import styled from 'styled-components';

export const FilterPanelItems = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  gap: 12px;

  .search-hide {
    width: 34px;
    box-shadow: none !important;
    transition: width 0.3s;

    input {
      width: 0;
      transition: width 1s;
    }
    .ant-input-suffix {
      padding: 0 5px;
    }
  }
  .search-visible {
    width: 50%;
    transition: width 0.6s;
    &:active,
    &:focus-within {
      box-shadow: none !important;
      outline: 1px solid #d9d9d9 !important;

      .ant-input-suffix {
        border-left: 1px solid #d9d9d9 !important;
        padding: 0 5px;
      }
    }
  }
`;
