import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';

interface Props {
  text: string;
}

export const FormItemDescription: React.FunctionComponent<Props> = ({ text }) => {
  return (
    <Space style={{ marginBottom: 24 }}>
      <InfoCircleOutlined className="light-description" />
      <Typography className="bold-12 light-description">{text}</Typography>
    </Space>
  );
};
