import React, { createContext, useContext, useState, useEffect } from 'react';
import { authHandler, refreshTokenHandler } from '../api/apiWorker';
import { AuthResponse, Providers } from '../types';
import moment from 'moment';

interface AuthContextType {
  user: AuthResponse | null;
  loading: boolean;
  login: (email: string, password: string, token?: string, provider?: Providers) => Promise<AuthResponse | null>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const getTokenExpirationDate = (token: string) => {
  const decoded = JSON.parse(atob(token.split('.')[1]));
  return decoded.exp ? moment.unix(decoded.exp) : null;
};

const AUTH_DATA_KEY = 'authData';

export const getStoredAuthData = (): AuthResponse | null => {
  const storedData = localStorage.getItem(AUTH_DATA_KEY);
  return storedData ? JSON.parse(storedData) : null;
};

const storeAuthData = (authData: AuthResponse) => {
  localStorage.setItem(AUTH_DATA_KEY, JSON.stringify(authData));
};

const removeStoredAuthData = () => {
  localStorage.removeItem(AUTH_DATA_KEY);
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<AuthResponse | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      const authData = getStoredAuthData();

      if (authData && authData.token) {
        const expirationDate = getTokenExpirationDate(authData.token);
        if (expirationDate && expirationDate.isBefore(moment().add(2, 'hours'))) {
          try {
            const data = await refreshTokenHandler(authData.token);
            if (data?.token) {
              storeAuthData(data);
              setUser(data);
            }
          } catch (error) {
            console.log('Token refresh error:', error);
          }
        } else {
          setUser(authData);
        }
      }
      setLoading(false);
    };

    initAuth();

    const intervalId = setInterval(initAuth, 79200000);

    return () => clearInterval(intervalId);
  }, []);

  const login = async (
    email: string,
    password: string,
    ssoToken?: string,
    provider?: Providers
  ): Promise<AuthResponse | null> => {
    const response = await authHandler(email, password, ssoToken, provider);
    if (response.token) {
      storeAuthData(response);
      setUser(response);
      return response;
    }
    return null;
  };

  const logout = () => {
    setUser(null);
    removeStoredAuthData();
  };

  const value = { user, loading, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
