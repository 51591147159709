import React, { useState } from 'react';
import { ButtonSubmit, PopUpWrapper, StyledInput, StyledPopUpContent, StyledPopupHeader } from '../ScriptStyles';
import CloseIcon from '../../../assets/close.png';
import { Form, message, Space, Spin, Typography } from 'antd';
import { updateResource } from '../../../api/apiWorker';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface EditFolderForm {
  title: string;
}

interface IPopupDuplicateProps {
  setShowPopupEditFolder: React.Dispatch<React.SetStateAction<boolean | null>>;
  fetchScripts?: () => Promise<void>;
  resourceId: string;
  resourceName: string;
}

const StyledLabel = styled.p`
  font-size: 16px;
  font-weight: 800;
  line-height: 23.76px;
  color: var(--light-description);
`;

const PopUpContent = styled(StyledPopUpContent)`
  @media (max-width: 590px) {
    max-width: 390px;
  }
`;

const Input = styled(StyledInput)`
  height: 51px;
`;

export const PopupEditFolder: React.FC<IPopupDuplicateProps> = ({
  resourceId,
  resourceName,
  setShowPopupEditFolder,
  fetchScripts
}) => {
  const [form] = Form.useForm<EditFolderForm>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableButtonsSubmit, setIsDisableButtonSubmit] = useState(true);

  const handleSubmit = async (value: EditFolderForm) => {
    setIsLoading(true);
    setIsDisableButtonSubmit(true);
    try {
      await updateResource({
        resource_id: resourceId,
        resource_name: value.title
      });
      fetchScripts && fetchScripts();
      setShowPopupEditFolder(false);
    } catch (e) {
      message.error('Failed edit folder');
    } finally {
      setIsLoading(false);
      setIsDisableButtonSubmit(false);
    }
  };

  const handleValueChange = () => {
    const values = form.getFieldsValue();
    if (!values.title || resourceName.trim() === values.title.trim()) {
      setIsDisableButtonSubmit(true);
    } else {
      setIsDisableButtonSubmit(false);
    }
  };

  return (
    <PopUpWrapper>
      <PopUpContent>
        <StyledPopupHeader>
          <Typography.Text className="text-title fnt-open-sans" style={{ fontSize: '24px', fontWeight: '700' }}>
            Edir Folder
          </Typography.Text>

          <div className="close" onClick={() => setShowPopupEditFolder(null)}>
            <img src={CloseIcon} alt={''} />
          </div>
        </StyledPopupHeader>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          disabled={isLoading}
          initialValues={{ title: resourceName }}
          style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
          onValuesChange={handleValueChange}
        >
          <Form.Item
            style={{
              width: '100%',
              marginBottom: '0',
              textAlign: 'left'
            }}
            name="title"
            label={<StyledLabel className="fnt-rounded">Folder Name</StyledLabel>}
          >
            <Input type="text" size="large" className="fnt-mulish primary-text" placeholder="Enter name" />
          </Form.Item>

          <ButtonSubmit type="button" onClick={() => form.submit()} disabled={isDisableButtonsSubmit || isLoading}>
            <Space>
              {' '}
              Save Changes
              <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="small" />
            </Space>
          </ButtonSubmit>
        </Form>
      </PopUpContent>
    </PopUpWrapper>
  );
};
