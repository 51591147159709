import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 420px;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 16px;
  background-color: var(--light-gray);
`;

const ImageContainer = styled.div<{ active: boolean }>`
  height: 80px;
  width: 80px;
  padding: 4px;
  background-color: #fff;
  border-radius: 16px;
  border: 2px solid ${props => (props.active ? 'var(--primary-color)' : '#fff')};
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

interface Props {
  imageList: { name: string; file_name: string }[];
  selectedItems: string[];
  path: string;
  onImageSelect: (imageName: string) => void;
}

export const ImageSelectBox: React.FunctionComponent<Props> = ({ imageList, selectedItems, path, onImageSelect }) => {
  if (!imageList?.length) return null;

  return (
    <Container>
      {imageList.map(item => (
        <ImageContainer key={item.name} active={selectedItems.includes(item.name)} onClick={() => onImageSelect(item.name)}>
          <img alt={item.name} src={path + item.file_name} />
        </ImageContainer>
      ))}
    </Container>
  );
};
