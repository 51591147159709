import React from 'react';
import styled from 'styled-components';
import { BackArrowIcon } from '../assets/icons';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

const BackLinkWrapper = styled.div`
  width: 100%;
  max-width: 1450px;
  padding-left: 24px;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;

  .back-link-title {
    font-size: 24px;
    font-weight: 800;

    @media (max-width: 1120px) {
      font-size: 16px;
    }
  }
`;

interface Props {
  title: string;
  path: string;
}

export const BackLink: React.FunctionComponent<Props> = ({ title, path }) => {
  return (
    <Container>
      <BackLinkWrapper>
        <StyledLink to={path}>
          <BackArrowIcon className="primary-text" />
          <Typography.Text className="primary-text back-link-title">{title}</Typography.Text>
        </StyledLink>
      </BackLinkWrapper>
    </Container>
  );
};
