import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { Modal } from 'antd';

const usePrompt = (blocked: boolean) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => blocked && currentLocation.pathname !== nextLocation.pathname
  );
  useEffect(() => {
    if (blocker.state === 'blocked') {
      Modal.confirm({
        title: 'Are you sure?',
        content: 'The script has unsaved changes. Do you want to leave edit mode without saving?',
        okText: 'Discard',
        cancelText: 'Cancel',
        okButtonProps: { danger: true },
        closable: true,
        onOk: () => blocker.proceed(),
        onCancel: () => blocker.reset()
      });
    }
  }, [blocker, blocked]);

  return blocker;
};

export default usePrompt;
