import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Account, FilterOptions, OrganizationItem, SortOptions } from '../../types';
import { CreateAccountBtnWrapper, PageContainer, PageContent, PageWrapper, StyledButton } from '../../assets/styles/Styled';
import { Space } from 'antd';
import { Search } from '../../components/Search';
import { useAppContext } from '../../context/AppContext';
import { AccountsTable } from './AccountsTable';
import { FilterItems, FilterPanel } from '../ScriptsPage/FilterPanel';
import { extractOrganizationsFromAccounts } from '../../helper';

interface Filters {
  search: string;
  filter: FilterItems;
}

const AdminDashboard = () => {
  const { accounts, accountsLoading, fetchAccounts } = useAppContext();
  const [accountOrganizations, setAccountOrganizations] = useState<string[]>([]);
  const { globalLoading } = useAppContext();
  const [filters, setFilters] = useState<Filters>({
    search: '',
    filter: { option: FilterOptions.all, selectedOrganizations: null }
  });
  const [sortOption, setSortOption] = useState<SortOptions>(SortOptions.az);

  useEffect(() => {
    const organizationList = extractOrganizationsFromAccounts(accounts);
    setAccountOrganizations(organizationList);
  }, [accounts]);
  const filteredAccounts: Account[] = useMemo(() => {
    let result = accounts?.filter(
      account =>
        account.first_name?.toLowerCase().includes(filters.search.toLowerCase()) ||
        account.last_name?.toLowerCase().includes(filters.search.toLowerCase()) ||
        account.email?.toLowerCase().includes(filters.search.toLowerCase())
    );
    const { option, selectedOrganizations } = filters.filter;
    if (option === FilterOptions.withoutOrganization) {
      result = result.filter(script => !script.organizations.length);
    }
    if (selectedOrganizations?.length) {
      result = result.filter(script => {
        return script.organizations.some(org => selectedOrganizations.includes(org.organization_name));
      });
    }

    switch (sortOption) {
      case SortOptions.az:
        result.sort((a, b) => a.email?.localeCompare(b?.email));
        break;
      case SortOptions.za:
        result.sort((a, b) => b.email?.localeCompare(a?.email));
        break;
      default:
        break;
    }

    return result;
  }, [accounts, filters, sortOption]);
  return (
    <PageWrapper>
      <PageContainer>
        <PageContent>
          <Space direction="vertical" size={24} className="w-100">
            <CreateAccountBtnWrapper>
              <Link to={'/user-registration'}>
                <StyledButton>Create Account</StyledButton>
              </Link>
            </CreateAccountBtnWrapper>
            <Search placeholder="Created Accounts" onChange={search => setFilters(prev => ({ ...prev, search }))} />
          </Space>
          <FilterPanel
            defaultSortOption={SortOptions.az}
            sortItems={[SortOptions.az, SortOptions.za]}
            organizations={accountOrganizations}
            onSort={opt => setSortOption(opt)}
            onFilterChange={filter => setFilters(prev => ({ ...prev, filter }))}
          />
          <AccountsTable
            tableData={filteredAccounts}
            loading={accountsLoading || globalLoading.spin}
            getAllAccounts={fetchAccounts}
          />
        </PageContent>
      </PageContainer>
    </PageWrapper>
  );
};

export default AdminDashboard;
