import { Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { IActionsConfigItems, IPreviewConfig } from '../../../../../types';
import styled from 'styled-components';
import { StyledButton } from '../../../../../assets/styles/Styled';
import { useScriptForm } from '../../../ScriptCreateStore';

interface ISelectModalProps {
  backgrounds: IActionsConfigItems[];
  basePath: string;
  openModal: boolean;
  setPreviewConfig: React.Dispatch<React.SetStateAction<IPreviewConfig>>;
}

const StyledTitle = styled(Typography.Paragraph)`
  color: #062e3a;
  font-family: 'Rounded Mplus 1c', 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
`;

const ModalContainer = styled.div`
  display: flex;
  // justify-content: space-between;

  flex-wrap: wrap;
  gap: 8px;
`;

const ImgWrapper = styled.div`
  width: 80px;
  height: 80px;
  background: #fff;
  box-shadow: 0px 3.45px 13.79px 0px #00000012;
  border-radius: 19px;
  overflow: hidden;
  border: 2px solid #fff;

  &.selected {
    border-color: #7e42fc;
  }

  &:hover {
    border-color: #7e42fc;
    transition: 0.5s ease;
    cursor: pointer;
  }
  > img {
    width: 100%;
    height: 100%;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    height: 430px;
    width: 568px;
    background: #f5fbfc;
    padding: 16px;
    overflow-y: auto;
    @-moz-document url-prefix() {
      width: 560px;
    }

    @media (max-width: 590px) {
      & {
        width: 480px;
        @-moz-document url-prefix() {
          width: 470px;
        }
      }
    }
    @media (max-width: 500px) {
      & {
        width: 392px;
        @-moz-document url-prefix() {
          width: 382px;
        }
      }
    }
    @media (max-width: 390px) {
      & {
        width: 304px;
        @-moz-document url-prefix() {
          width: 294px;
        }
      }
    }
    @media (max-width: 300px) {
      width: 206px;
      & {
        max-width: 206px;
      }
    }

    & .ant-modal-footer {
      position: sticky;
      bottom: 0;
    }
  }
`;

export const ModalSelectBackground: React.FC<ISelectModalProps> = ({ openModal, backgrounds, basePath, setPreviewConfig }) => {
  const [selectedBackground, setSelectedBackground] = useState(() => {
    const backgroundDay = backgrounds.filter(item => item.id === 'Day');
    return backgroundDay[0]?.id;
  });
  const { setFormValues } = useScriptForm();

  const handleOk = () => {
    setFormValues(prevState => ({ ...prevState, background: selectedBackground }));
  };

  const selectBackground = (backgroundId: string) => {
    setPreviewConfig((prev: IPreviewConfig) => ({
      ...prev,
      backgrounds: backgroundId
    }));
    setSelectedBackground(backgroundId);
  };

  return (
    <>
      <StyledModal
        open={openModal}
        style={{ height: '430px', width: '552px' }}
        title={<StyledTitle>Select initial background</StyledTitle>}
        onOk={handleOk}
        closable={false}
        footer={[
          <StyledButton className="btn-orange" key="submit" onClick={handleOk} disabled={!selectedBackground}>
            Select Background
          </StyledButton>
        ]}
      >
        <ModalContainer>
          {backgrounds.map(background => (
            <ImgWrapper
              className={background.id === selectedBackground ? 'selected' : ''}
              key={background.id}
              onClick={() => selectBackground(background.id)}
            >
              <img src={`${basePath}${background.id}_image.png`} alt={background.id} />
            </ImgWrapper>
          ))}
        </ModalContainer>
      </StyledModal>
    </>
  );
};
