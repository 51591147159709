import { useCallback, useEffect, useState } from 'react';
import { OrganizationItem } from '../types';
import { getOrganizations } from '../api/apiWorker';
import { message } from 'antd';

interface Props {
  manualFetch?: boolean | undefined;
}

export const useGetOrganizations = ({ manualFetch = false }: Props = {}) => {
  const [organizations, setOrganizations] = useState<OrganizationItem[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchOrganizations = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getOrganizations();
      setOrganizations(data || []);
    } catch (e) {
      message.error('Failed to fetch organizations');
      console.error('error:', e);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!manualFetch) {
      fetchOrganizations();
    }
  }, [manualFetch, fetchOrganizations]);

  return { organizations, loading, fetchOrganizations };
};
