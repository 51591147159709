import React, { useEffect, useMemo, useState } from 'react';
import { MenuProps, message, Space, Spin, TableProps, Typography } from 'antd';
import CloseIcon from '../../../assets/close.png';
// import { duplicateScript } from '../../../api/apiWorker';
import { ButtonSubmit, PopUpWrapper, StyledInput, StyledPopUpContent, StyledPopupHeader } from '../ScriptStyles';
import { FilterOptions, OrganizationItem, Resource, SortOptions } from '../../../types';
import { ScriptsTable } from '../ScriptsTable';
import { OrganizationTags } from '../../../components/OrganizationTags';
import { FileIcon, FolderIcon } from '../../../assets/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { FilterPanel } from '../FilterPanel';
import { extractOrganizationsFromScripts } from '../../../helper';
import { Filters } from '../ScriptsPage';
import { Search } from '../../../components/Search';
import { FilterPanelItems } from '../scriptPage.styled';
import { getResources } from '../../../api/apiWorker';
import { TableRowSelection } from 'antd/es/table/interface';

import styled from 'styled-components';

interface Props {
  resourceType: 'script' | 'folder';
  onRemove: (scriptId: string) => void;
  setShowPopupSelectScript: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const PopupContent = styled(StyledPopUpContent)`
  max-width: 830px;
`;
const StyledPopUpWrapper = styled(PopUpWrapper)`
  padding: 0 15px;
`;

const PanelWrapper = styled(FilterPanelItems)`
  .filter-panel {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export type IRowSelection = TableRowSelection<Resource>;

export const PopupSelectScriptOrFolder: React.FC<Props> = ({ resourceType, onRemove, setShowPopupSelectScript }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableButtonsSubmit, setIsDisableButtonSubmit] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [filters, setFilters] = useState<Filters>({
    search: '',
    filter: { option: FilterOptions.all, selectedOrganizations: null }
  });
  const [sortOption, setSortOption] = useState<SortOptions>(SortOptions.newest);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [scripts, setScripts] = useState<Resource[]>([]);
  const [scriptOrganizations, setScriptOrganizations] = useState<string[]>([]);

  const fetchResources = async () => {
    setIsLoading(true);
    try {
      const data = await getResources(`?resource_type=${resourceType}`);
      if (Array.isArray(data)) {
        setScripts(data);
        const organizationList = extractOrganizationsFromScripts(data);
        setScriptOrganizations(organizationList);
      } else {
        setScripts([]);
      }
    } catch (e) {
      message.error('Error fetching scripts');
      console.log(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchResources();
  }, []);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: IRowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const rowSelectionSingle: IRowSelection = {
    type: 'radio',
    onChange: onSelectChange
  };
  const dropdownMenu: MenuProps['items'] = [
    {
      label: <Typography className="normal-16 text-secondary fnt-mulish">Menu-1</Typography>,
      key: 'menu-1',
      onClick: () => console.log('CLICK MENU-1')
    },
    {
      label: <Typography className="normal-16 text-secondary fnt-mulish">Menu-2</Typography>,
      key: 'menu-2',
      disabled: false,
      onClick: () => console.log('Menu-2')
    }
  ];

  const filteredScripts: Resource[] = useMemo(() => {
    const folders = scripts.filter(script => script.resource_type === 'folder');
    const scriptFiles = scripts.filter(script => script.resource_type === 'script');

    let resultFolder = folders.filter(script => script.resource_name.toLowerCase().includes(filters.search.toLowerCase()));
    let resultScripts = scriptFiles.filter(script => script.resource_name.toLowerCase().includes(filters.search.toLowerCase()));

    const { option, selectedOrganizations } = filters.filter;
    if (option === FilterOptions.withoutOrganization) {
      resultFolder = folders.filter(folder => !folder.organization_names?.length);
      resultScripts = scriptFiles.filter(script => !script.organization_names?.length);
    }
    if (selectedOrganizations?.length) {
      resultFolder = resultFolder.filter(folder => {
        return folder.organization_names?.some(org => selectedOrganizations.includes(org));
      });
      resultScripts = resultScripts.filter(script => {
        return script.organization_names?.some(org => selectedOrganizations.includes(org));
      });
    }

    return [...resultFolder, ...resultScripts];
  }, [scripts, filters, sortOption]);

  const tableColumns: TableProps<Resource>['columns'] = [
    {
      title: 'Type',
      dataIndex: 'resource_type',
      key: 'resource_type',
      className: 'extrabold-14',
      width: '20%',
      render: (resource_type: Resource['resource_type']) =>
        resource_type === 'script' ? (
          <FileIcon style={{ width: '18px', height: '22px', verticalAlign: 'inherit' }} className="hint-color" />
        ) : (
          <FolderIcon style={{ width: '18px', height: '22px', verticalAlign: 'inherit' }} />
        )
    },
    {
      title: 'Name',
      dataIndex: 'resource_name',
      key: 'resource_name',
      className: 'extrabold-14',
      width: '30%',
      ellipsis: true
    },

    {
      title: 'Organization',
      dataIndex: 'organization_names',
      key: 'organization_names',
      width: '40%',
      render: (organizations: string[]) => <OrganizationTags organizations={organizations} />
    }
  ];
  return (
    <StyledPopUpWrapper>
      <PopupContent>
        <StyledPopupHeader>
          <Typography.Text className="text-title fnt-open-sans" style={{ fontSize: '24px', fontWeight: '700' }}>
            Select Scripts
          </Typography.Text>

          <div className="close" onClick={() => setShowPopupSelectScript(null)}>
            <img src={CloseIcon} alt={''} />
          </div>
        </StyledPopupHeader>
        <PanelWrapper className="PPPp">
          <FilterPanel
            organizations={scriptOrganizations}
            onSort={opt => setSortOption(opt)}
            onFilterChange={filter => setFilters(prev => ({ ...prev, filter }))}
            isShowSortPanel={false}
          />
          <Search
            className={`${toggleSearch ? 'search-visible' : 'search-hide'}`}
            placeholder=""
            onChange={search => setFilters(prev => ({ ...prev, search }))}
            setToggleSearch={setToggleSearch}
          />
        </PanelWrapper>
        <>
          <ScriptsTable
            tableData={filteredScripts}
            loading={isLoading}
            tableColumnsProps={tableColumns}
            rowSelection={resourceType === 'folder' ? rowSelectionSingle : rowSelection}
          />
        </>
        <ButtonSubmit type="button" disabled={!selectedRowKeys.length || isLoading}>
          <Space>
            {' '}
            Add Files
            <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="small" />
          </Space>
        </ButtonSubmit>
      </PopupContent>
    </StyledPopUpWrapper>
  );
};
