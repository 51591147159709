import React, { useMemo, useRef, useState } from 'react';
import { Button, ButtonProps, message, Progress, Space, Typography, Upload, UploadProps } from 'antd';
import { UploadIcon } from '../../../../assets/icons';
import { uploadScript, getLargeFileUploadData, fetchSlidesForUpload, uploadFileToS3 } from '../../../../api/apiWorker';
import styled from 'styled-components';
import { SelectedFile } from './SelectedFile';
import { FileType } from '../../../../types';
import { convertFormDataForS3 } from '../../../../helper';

const UploadButton: React.FC<ButtonProps> = props => (
  <Button type="primary" size="large" style={{ borderRadius: 20, width: '100%' }} {...props}>
    <Space size={4}>
      <Typography.Text className="extrabold-16 text-white">Add File</Typography.Text>
      <Typography.Text className="bold-10 text-white">(Png, Jpg, Pdf)</Typography.Text>
    </Space>
  </Button>
);

const Container = styled.div``;
const SelectedFilesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
`;

const StyledUpload = styled(Upload)`
  > div {
    width: 100%;
  }
`;

const rootStyle = getComputedStyle(document.documentElement);
const colorPrimary = rootStyle.getPropertyValue('--primary-color').trim();
const colorRed = rootStyle.getPropertyValue('--text-red').trim();
const colorGreen = rootStyle.getPropertyValue('--text-green').trim();
const colorViolet = rootStyle.getPropertyValue('--light-violet').trim();
const colorPink = rootStyle.getPropertyValue('--light-pink').trim();

const allowedFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];

type ProgressType = { percent: number | undefined; status: 'normal' | 'exception' | 'active' | 'success' | undefined };

interface Props {
  onChange: (imageList: string[]) => void;
  editMode: boolean;
  disabled: boolean;
}

export const Uploader: React.FunctionComponent<Props> = ({ onChange, editMode, disabled }) => {
  const [selectedFiles, setSelectedFiles] = useState<FileType[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<FileType[]>([]);
  const [progress, setProgress] = useState<ProgressType>({ percent: undefined, status: undefined });
  const fileCounter = useRef(0);
  const [isFileLargerThan5MB, setIsFileLargerThan5MB] = useState(false);
  const [indicatorLength, setIndicatorLength] = useState(0);
  let intervalIdGetSlides: NodeJS.Timeout;

  const S3Uploader = async (file: FileType) => {
    try {
      const data = await getLargeFileUploadData();
      if (!data) return;

      const formDataS3 = convertFormDataForS3(data.fields, file);

      const response = await uploadFileToS3(data.url, formDataS3);

      if (response.status === 204) {
        return data.upload_id;
      } else {
        console.error('File upload failed', response);
        message.error('File upload failed');
      }
    } catch (error: unknown) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const beforeUpload = (file: FileType, fileList: FileType[]) => {
    if (!allowedFileTypes.includes(file.type)) {
      message.error(`File ${file.name} is not supported`);
      return false;
    }
    if (fileList.length === 1 && file.type === 'application/pdf' && file.size / 1024 / 1024 > 4.5) {
      if (file.size / 1024 / 1024 >= 50) {
        message.error('File size must be smaller than 50MB!');
        return false;
      }
      setIsFileLargerThan5MB(true);
      setSelectedFiles(fileList);
      return true;
    }
    const totalSize = fileList.reduce((total, currentFile) => total + currentFile.size, 0);
    const isTotalLt5M = totalSize / 1024 / 1024 < 4.5;

    if (!isTotalLt5M) {
      message.error('Total file size must be smaller than 4.5MB!');
      return false;
    }

    setSelectedFiles(fileList);
    return true;
  };

  const handleFetchSlidesForUpload = async (uploadId: string) => {
    try {
      setIndicatorLength(prev => {
        const newLength = prev + (prev < 53 ? 0.5 : 0);
        setProgress({ percent: 43 + newLength, status: 'active' });
        return newLength;
      });
      const data = await fetchSlidesForUpload(uploadId);
      if (data?.slides) {
        clearInterval(intervalIdGetSlides);
        onChange(data.slides);
        return true;
      }
      return false;
    } catch (e) {
      console.error('Error in handleFetchSlidesForUpload:', e);
      throw e;
    }
  };

  const waitForSlides = (currentUploadId: string) => {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();
      const oneMinute = 60000;

      intervalIdGetSlides = setInterval(async () => {
        try {
          const isCompleted = await handleFetchSlidesForUpload(currentUploadId);
          if (isCompleted) {
            clearInterval(intervalIdGetSlides);
            resolve(true);
            setIndicatorLength(0);
          }
        } catch (error: any) {
          const currentTime = Date.now();
          const elapsedTime = currentTime - startTime;

          if (error.response && error.response.status === 404) {
            if (elapsedTime >= oneMinute) {
              clearInterval(intervalIdGetSlides);
              reject(error);
            }
          } else {
            console.error('Error in waitForSlides:', error);
            clearInterval(intervalIdGetSlides);
            reject(error);
          }
        }
      }, 1000);
    });
  };

  const handleUpload = async (options: any) => {
    const { onSuccess, onError, onProgress } = options;
    // Prevent multiple upload calls
    fileCounter.current += 1;
    if (fileCounter.current < selectedFiles.length) {
      onSuccess('Ok');
      return;
    }

    const formData = new FormData();

    selectedFiles.forEach(file => {
      formData.append('scripts', file);
    });
    setUploadedFiles(prev => [...prev, ...selectedFiles]);
    try {
      if (isFileLargerThan5MB) {
        setProgress({ percent: 40, status: 'active' });

        const currentUploadId = await S3Uploader(selectedFiles[0]);
        if (currentUploadId) {
          await waitForSlides(currentUploadId);
        }
      } else {
        const { data } = await uploadScript(formData, percent => {
          onProgress(percent);
          setProgress({ percent, status: 'active' });
        });
        onChange(data);
      }

      if (fileCounter.current > 1) {
        message.success('All files uploaded successfully');
      } else {
        message.success('File uploaded successfully');
      }
      onSuccess('Ok');
      setProgress({ percent: 100, status: 'success' });
      setTimeout(() => setProgress({ percent: 100, status: 'normal' }), 500);
    } catch (e: any) {
      console.error('Upload error:', e);
      if (e.response?.data?.code && e.response?.data?.code === 'validation_error') {
        message.error(e.response?.data?.detail ? e.response.data.detail : "File wasn't uploaded");
      } else {
        message.error("File wasn't uploaded");
      }
      onError('Upload error');
      setProgress({ percent: 50, status: 'exception' });
    } finally {
      fileCounter.current = 0;
      setSelectedFiles([]);
    }
  };

  const progressBarColor = useMemo(() => {
    if (progress.status === 'active') return colorPrimary;
    if (progress.status === 'success') return colorGreen;
    if (progress.status === 'exception') return colorRed;
    return colorPrimary;
  }, [progress]);

  const showBigUploader = useMemo(() => {
    return !!uploadedFiles.length || editMode;
  }, [uploadedFiles, editMode]);

  const uploadProps: UploadProps = {
    name: 'scripts',
    multiple: true,
    beforeUpload: beforeUpload,
    customRequest: handleUpload,
    accept: '.png,.jpg,.jpeg,.pdf',
    style: { padding: '16px 60px', display: showBigUploader ? 'none' : 'block' },
    fileList: []
  };

  return (
    <Container>
      <Upload.Dragger {...uploadProps}>
        <Space className="w-100" direction="vertical" size={16}>
          <p className="ant-upload-drag-icon">
            <UploadIcon />
          </p>
          <Space className="w-100" direction="vertical" size={8}>
            <Typography className="bold-16 primary-color">Upload a presentation</Typography>
            <Typography className="bold-12 violet-solid">
              The total size of files should be less than 5mb and contain less than 60 slides
            </Typography>
          </Space>
          <Space className="w-100" direction="vertical" size={8}>
            <UploadButton />
            <Typography.Text className="bold-12 violet-solid">Or drag the file here</Typography.Text>
          </Space>
        </Space>
      </Upload.Dragger>
      {(!!uploadedFiles.length || editMode) && (
        <SelectedFilesContainer>
          <StyledUpload {...uploadProps} disabled={disabled}>
            <UploadButton disabled={disabled} />
          </StyledUpload>
          {uploadedFiles.map(file => (
            <SelectedFile key={file.uid} completed={!!progress.status} file={file} onRemove={uid => console.log(uid)} />
          ))}
          {progress.status && progress.status !== 'normal' && (
            <Progress
              size={['100%', 12]}
              trailColor={progress.status === 'exception' ? colorPink : colorViolet}
              strokeColor={progressBarColor}
              status={progress.status}
              percent={progress.percent}
              showInfo={false}
            />
          )}
        </SelectedFilesContainer>
      )}
    </Container>
  );
};
