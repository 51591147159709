import { Checkbox, Select } from 'antd';
import styled from 'styled-components';
import { inputStyles, SharingButton, StyledCustomFormTag, StyledLabel } from '../../assets/styles/Styled';
import { StyledFieldsWrapper } from '../LandingPage/styled';

export const StyledCharacterLabel = styled(StyledLabel)``;
export const ItemFieldsWrapper = styled(StyledFieldsWrapper)`;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selection-placeholder {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    color: #afccd2 !important;
    font-size: 16px;
    line-height: 20px;
  }

  .ant-select-selection-search > input {
    color: #062e3a !important;
    font-size: 16px !important;
    padding: 0 16px;
  }
  .ant-select-selector {
    ${inputStyles}
    border-color:  transparent !important;

    &:hover {
      border-color: #9f6bff !important;
    }
  }
  &.ant-select-disabled {
    .ant-select-selector {
      &:hover {
        border-color: transparent !important;
        cursor: not-allowed !important;
      }
    }
  }

  .ant-select-selection-item {
    align-items: center !important;
    height: 44px !important;
  }
  .ant-select-selection-item-remove {
    font-size: 18px !important;
    color: #afccd2 !important;
  }
`;

export const StyledSharingButton = styled(SharingButton)``;

export const SharingList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const CustomTag = styled(StyledCustomFormTag)``;

export const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: #7da1a9 !important;
    color: #7da1a9 !important;

    &::after {
      border-color: #7da1a9 !important;
    }
  }
`;
