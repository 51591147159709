import React from 'react';
import { OrganizationItem } from '../types';
import { Space, Tag } from 'antd';
import styled from 'styled-components';

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const StyledTag = styled(Tag)`
  margin: 0;
  ${props => (!props.color ? 'color: var(--primary-color)' : null)};
  ${props => (!props.color ? 'background-color: var(--light-violet)' : null)};
  @media (max-width: 900px) {
    font-size: 10px;
    height: 20px;
    line-height: 18px;
    padding: 0 5px;
  }

  @media (max-width: 500px) {
    font-size: 8px;
    height: 18px;
    line-height: 16px;
    padding: 0 3px;
  }
`;

interface Props {
  organizations: string[];
}

export const OrganizationTags: React.FunctionComponent<Props> = ({ organizations }) => {
  if (!organizations?.length) {
    return <StyledTag color="blue">None</StyledTag>;
  }

  return (
    <TagContainer>
      {organizations.map((org, idx) => (
        <StyledTag key={`${org}-${idx}`} bordered={false}>
          {org || 'N/A'}
        </StyledTag>
      ))}
    </TagContainer>
  );
};
