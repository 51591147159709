import React, { createContext, useEffect } from 'react';
import { Modal } from 'antd';

const ReachableContext = createContext<string | null>(null);

interface IModalWarningChangeVersion {
  modalVisible: boolean;
  newVersion: string;
  setIsVisibleModal: (value: boolean) => void;
  updateVersion: (newVersion: string) => void;
}

export const ModalWarningChangeVersion: React.FC<IModalWarningChangeVersion> = ({
  modalVisible,
  newVersion,
  setIsVisibleModal,
  updateVersion
}) => {
  const config = {
    title: 'Are you sure?',
    content: `This will set minimum required client version to ${newVersion}.`,
    okText: 'Continue',
    cancelText: 'Cancel',
    onOk: () => {
      updateVersion(newVersion);
      setIsVisibleModal(false);
    },
    onCancel: () => {
      setIsVisibleModal(false);
    }
  };
  const [modal, contextHolder] = Modal.useModal();

  const openModal = async () => {
    await modal.confirm(config);
  };

  useEffect(() => {
    modalVisible && openModal();
  }, [modalVisible]);

  return <ReachableContext.Provider value="Light">{contextHolder}</ReachableContext.Provider>;
};
