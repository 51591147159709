import { useState, useEffect } from 'react';

const useMedia = ({ mobileMaxWidth = 767, tabletMinWidth = 768, tabletMaxWidth = 1024 } = {}) => {
  const mobileQuery = `(max-width: ${mobileMaxWidth}px)`;
  const tabletQuery = `(min-width: ${tabletMinWidth}px) and (max-width: ${tabletMaxWidth}px)`;
  const desktopQuery = `(min-width: ${tabletMaxWidth + 1}px)`;

  const [isMobile, setIsMobile] = useState(window.matchMedia(mobileQuery).matches);
  const [isTablet, setIsTablet] = useState(window.matchMedia(tabletQuery).matches);
  const [isDesktop, setIsDesktop] = useState(window.matchMedia(desktopQuery).matches);

  useEffect(() => {
    const mobileMediaQueryList = window.matchMedia(mobileQuery);
    const tabletMediaQueryList = window.matchMedia(tabletQuery);
    const desktopMediaQueryList = window.matchMedia(desktopQuery);

    const handleMobileChange = (e: MediaQueryListEvent) => setIsMobile(e.matches);
    const handleTabletChange = (e: MediaQueryListEvent) => setIsTablet(e.matches);
    const handleDesktopChange = (e: MediaQueryListEvent) => setIsDesktop(e.matches);

    mobileMediaQueryList.addListener(handleMobileChange);
    tabletMediaQueryList.addListener(handleTabletChange);
    desktopMediaQueryList.addListener(handleDesktopChange);

    setIsMobile(mobileMediaQueryList.matches);
    setIsTablet(tabletMediaQueryList.matches);
    setIsDesktop(desktopMediaQueryList.matches);

    return () => {
      mobileMediaQueryList.removeListener(handleMobileChange);
      tabletMediaQueryList.removeListener(handleTabletChange);
      desktopMediaQueryList.removeListener(handleDesktopChange);
    };
  }, [mobileQuery, tabletQuery, desktopQuery]);

  return { isMobile, isTablet, isDesktop };
};

export default useMedia;
