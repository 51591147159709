import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Input, message, Popconfirm, Typography } from 'antd';
import { CheckIcon, CloseCircleIcon, DragIcon, PenIcon } from '../../../../../assets/icons';
import { useScriptForm } from '../../../ScriptCreateStore';
import { DeleteFilled } from '@ant-design/icons';
import { ScenarioBlock, Sections } from '../../../../../types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const TextAreaContainer = styled.div`
  position: relative;
`;

const ReplicaContainer = styled.div`
  position: relative;
  padding: 16px 70px 16px 50px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.07);

  .drag-icon-absolute {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .pen-icon-absolute {
    position: absolute;
    top: 16px;
    right: 42px;
  }

  .delete-icon-absolute {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 22px;
  }
`;

const StyledTextArea = styled(Input.TextArea)`
  padding-right: 70px;
  resize: none;
`;

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  right: 16px;
  bottom: 16px;
  height: 52px;
  width: 52px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--additional-blue);
  cursor: pointer;
`;

const StyledCloseCircleIcon = styled(CloseCircleIcon)`
  position: absolute;
  top: 16px;
  right: 26px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: var(--light-gray);
  color: var(--hint-color);
  cursor: pointer;
`;

interface Props {
  block: ScenarioBlock;
  scenarioId: string;
}

export const Replica: React.FunctionComponent<Props> = ({ block, scenarioId }) => {
  const { content, id: blockId } = block;
  const {
    formValues: { scenario: scenarioState },
    setFormValues,
    sectionErrors
  } = useScriptForm();
  const [editing, setEditing] = useState(false);
  const [newContent, setNewContent] = useState(content);
  const textAreaRef = useRef<any>(null);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: blockId });

  useEffect(() => {
    if (editing && textAreaRef.current) {
      const textArea = textAreaRef.current.resizableTextArea?.textArea;
      textArea.focus();
      textArea.setSelectionRange(textArea.value.length, textArea.value.length);
    }
  }, [editing]);

  const missingContent = useMemo(() => {
    return !content && sectionErrors.section === Sections.Scenario ? scenarioId === sectionErrors.errorId : false;
  }, [content, scenarioId, sectionErrors]);

  const handleChange = useCallback(
    (content: string) => {
      if (!content) return message.error("Content can't be blank");

      const updatedScenarioItems = scenarioState.map((scenario, index) => {
        if (scenario.id === scenarioId) {
          const updatedScenario = { ...scenario };
          updatedScenario.blocks = updatedScenario.blocks.map(block => {
            return block.id === blockId ? { ...block, content } : block;
          });
          return updatedScenario;
        } else {
          return scenario;
        }
      });

      setFormValues(prevState => ({ ...prevState, scenario: updatedScenarioItems }));
      setEditing(false);
    },
    [scenarioState, scenarioId, blockId, setFormValues]
  );

  const handleRemove = () => {
    const updatedScenarioItems = scenarioState.map(scenario => {
      if (scenario.id === scenarioId) {
        const updatedBlocks = scenario.blocks.filter(block => block.id !== blockId);
        return { ...scenario, blocks: updatedBlocks };
      } else {
        return scenario;
      }
    });

    setFormValues(prevState => ({ ...prevState, scenario: updatedScenarioItems }));
  };

  const draggableStyle = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 'auto',
    opacity: isDragging ? 0.5 : 1
  };

  return (
    <div>
      {!content || editing ? (
        <TextAreaContainer>
          <StyledTextArea
            ref={textAreaRef}
            status={missingContent ? 'error' : ''}
            autoFocus
            rows={6}
            value={newContent}
            onChange={e => setNewContent(e.target.value)}
          />
          <StyledCloseCircleIcon
            onClick={() => {
              if (editing) {
                setEditing(false);
                setNewContent(content);
              } else {
                handleRemove();
              }
            }}
          />
          <StyledCheckIcon onClick={() => handleChange(newContent)} />
        </TextAreaContainer>
      ) : (
        <ReplicaContainer ref={setNodeRef} style={draggableStyle} {...attributes}>
          <DragIcon {...listeners} className="icon-gray drag-icon-absolute cursor-grab" />
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            <Typography.Paragraph className="normal-16 m-0">{content}</Typography.Paragraph>
          </pre>
          <PenIcon className="icon-gray cursor-pointer pen-icon-absolute" onClick={() => setEditing(true)} />
          <Popconfirm title="Delete this Replic?" okText="Delete" okButtonProps={{ danger: true }} onConfirm={handleRemove}>
            <DeleteFilled className="icon-gray cursor-pointer delete-icon-absolute" />
          </Popconfirm>
        </ReplicaContainer>
      )}
    </div>
  );
};
