import { useCallback, useEffect, useState } from 'react';
import { getActionsConfig, getBaseConfigs } from '../api/apiWorker';
import { message } from 'antd';
import { IActionsConfig, IBaseConfigs } from '../types';

interface Item {
  name: string;
  file_name: string;
}

interface SubCategory {
  [subCategoryName: string]: Item[];
}

interface Category {
  file_name: string;
  sub_categories: SubCategory;
}

export interface OrganizedLiveObjects {
  [categoryName: string]: Category;
}

interface Props {
  manualFetch?: boolean | undefined;
}

export const useGetActionsConfig = ({ manualFetch = false }: Props = {}) => {
  const [loading, setLoading] = useState(false);
  const [isPreloadImg, setIsPreloadImg] = useState(false);

  const [actionsConfig, setActionsConfig] = useState<IActionsConfig>({
    character_actions: [],
    backgrounds: [],
    character_moving_actions: [],
    awards: [],
    effects: [],
    drawing: [],
    whiteboard: [],
    live_object: []
  });
  const [baseActionPaths, setBaseActionPaths] = useState<IBaseConfigs>({
    actions_media_base_path: '',
    live_objects_media_base_path: ''
  });
  useEffect(() => {
    if (actionsConfig.backgrounds.length && baseActionPaths.actions_media_base_path && !isPreloadImg) {
      actionsConfig.backgrounds.forEach(url => {
        const img = new Image();
        img.src = `${baseActionPaths.actions_media_base_path}${url.id}_image.png`;
      });
      setIsPreloadImg(true);
    }
  }, [actionsConfig.backgrounds, baseActionPaths.actions_media_base_path]);

  const fetchActionsConfig = useCallback(async () => {
    setLoading(true);
    const customOrder = ['WhiteboardOn', 'WhiteboardNextSlide', 'WhiteboardOff'];
    try {
      const data = await getActionsConfig();
      if (data?.whiteboard.length) {
        const sortedWhiteboard = data.whiteboard.sort((a, b) => {
          return customOrder.indexOf(a) - customOrder.indexOf(b);
        });
        setActionsConfig({
          ...data,
          whiteboard: sortedWhiteboard,
          live_object: ['place_live_objects', 'delete_all_live_objects']
        });
      } else {
        data && setActionsConfig(data);
      }
    } catch (e) {
      message.error('Unable to fetch actions config');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchBaseConfigs = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getBaseConfigs();
      data && setBaseActionPaths(data);
    } catch (e) {
      message.error('Unable to fetch actions config');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!manualFetch) {
      fetchActionsConfig();
      fetchBaseConfigs();
    }
  }, [manualFetch, fetchActionsConfig]);

  return { actionsConfig, loading, baseActionPaths, fetchActionsConfig, fetchBaseConfigs };
};
