import { v4 as uuidv4 } from 'uuid';

import {
  PlayAnimationIcon,
  BackgroundIcon,
  ChangePositionIcon,
  AwardsIcon,
  PaintModeIcon,
  DeleteDrawingIcon,
  CloseIcon,
  WhiteboardIcon,
  NextIcon,
  SelectActionLoIcon,
  DeleteAllLoIcon,
  PlaceLoIcon
} from './../../../assets/icons';
import { Menu, MenuProps, Typography } from 'antd';
import {
  ActionButtonEnum,
  IActionsConfig,
  IActionsType,
  IBaseConfigs,
  IPaintAction,
  LoItem,
  MenuItem,
  NewActionsItem,
  Sections,
  WhiteboardPreview,
  WhiteboardPreviewItems
} from '../../../types';
import styled from 'styled-components';
import { useCallback, useMemo } from 'react';
import { usePreview } from '../../../context/PreviewContext';
import { useAppContext } from '../../../context/AppContext';

const StyledMenuItem = styled(Menu.Item)`
  background-color: #fdedd6 !important;
  color: #ff8832 !important;
  &:hover {
    background-color: transparent !important;
  }
`;

const StyledButtonOl = styled.button`
  color: var(--orange-solid);
  border: none;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 48px;
  width: 100%;
  border-radius: 40px;
  background: #fff;
  font-size: 16px;
  font-weight: 700;
  transition: filter 0.3s ease;
  border: 2px solid var(--orange-solid);
  &:hover {
    filter: brightness(110%);
  }
`;

const ImageActions = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledTitleAction = styled(Typography.Text)`
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #afccd2;
  line-height: 20.08px;
  margin: 0 0 10px;
  display: block;
`;

const StyledAwardsList = styled.ul`
  justify-content: flex-start;
`;

const StyledAwardsItem = styled.li`
  width: 80px;
  height: 80px;
  background: #fff;
  box-shadow: 0px 3.45px 13.79px 0px #00000012;
  border: 2px solid #fff;
  border-radius: 19px;
  overflow: hidden;
  padding: 7px;
  &.selected,
  &:hover {
    border-color: var(--primary-color);
  }

  & > img {
    width: 100%;
    height: 100%;
  }
`;

const StyledActionsItem = styled.div`
  width: 80px;
  height: 80px;
  background: #fff;
  box-shadow: 0px 3.45px 13.79px 0px #00000012;
  border-radius: 19px;
  overflow: hidden;
  border: 2px solid #fff;

  &.selected,
  &:hover {
    border-color: var(--primary-color);
  }
  &.disabled {
    pointer-events: none;
  }

  & > img {
    width: 100%;
    height: 100%;
  }
  &.lo-image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const StyledDrawingItem = styled.div`
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.08px;
  color: var(--orange-solid);
  display: flex;
  align-items: center;
  &.selected,
  &:hover {
    filter: brightness(85%);
  }
  & span {
    margin-right: 8px;
    & svg {
      path {
        fill: var(--orange-solid);
        stroke: var(--orange-solid);
        &:nth-child(1) {
          stroke: none;
        }
      }
    }
  }
  &.stroke-none {
    & span {
      & svg {
        path {
          fill: var(--orange-solid);
          stroke: none;
        }
      }
    }
  }
`;

export const useNoteActions = ({
  menuItems = [],
  actionsConfig,
  baseActionPaths,
  isShowWhiteboardMenu,
  isShowOnlyWhiteboardOff,
  blockId,
  liveObjects,
  liveObjectsBasePath,
  newActions,
  slides,
  setSelectedAction,
  setNewActions,
  setWhiteboardEditableBlock,
  setWhiteboardPreview,
  setCurrentSection
}: {
  menuItems: MenuItem[];
  actionsConfig: IActionsConfig;
  baseActionPaths: IBaseConfigs;
  isShowWhiteboardMenu: boolean;
  isShowOnlyWhiteboardOff: boolean;
  blockId: string;
  liveObjects: string[];
  liveObjectsBasePath: string;
  newActions: NewActionsItem[];
  slides: string[];
  setSelectedAction: React.Dispatch<React.SetStateAction<string[]>>;
  setNewActions: React.Dispatch<React.SetStateAction<NewActionsItem[]>>;
  setWhiteboardEditableBlock: React.Dispatch<React.SetStateAction<WhiteboardPreview>>;
  setWhiteboardPreview: React.Dispatch<React.SetStateAction<WhiteboardPreview>>;
  setCurrentSection: React.Dispatch<React.SetStateAction<Sections>>;
}) => {
  const {
    setLoToPreview,
    setLastSavedCharacterPosition,
    lastSavedCharacterPosition,
    previewConfig,
    whiteboardIconIdx,
    setPreviewConfig,
    setPaintToPreview
  } = usePreview();
  const { liveObjectWithId } = useAppContext();

  const handleMenuClick = useCallback(
    (e: any) => {
      const key = e.key as string;

      const menuItem = menuItems.find(item => item.key === key);
      if (menuItem) {
        setSelectedAction(prev => [...prev, menuItem.key]);
        setNewActions(prev => {
          let idx = 0;
          if (prev.length) {
            const keys = prev.map(item => +item.dropdownKey.split('-').reverse()[0]);
            idx = Math.max(...keys) + 1;
          }
          return [
            ...prev,
            {
              action_type: menuItem.key,
              id: '',
              dropdownKey: `${menuItem.key}-${idx}`
            }
          ];
        });
      }
    },
    [menuItems, setSelectedAction]
  );

  const actionIcons: { [key: string]: JSX.Element } = useMemo(
    () => ({
      character_actions: <PlayAnimationIcon />,
      backgrounds: <BackgroundIcon />,
      character_moving_actions: <ChangePositionIcon />,
      awards: <AwardsIcon />,
      drawing: <PaintModeIcon className="icon-menu-actions" />,
      DrawingOn: <PaintModeIcon className="icon-menu-actions-item" />,
      DrawingOff: <CloseIcon className="icon-menu-actions-item" />,
      DrawingClear: <DeleteDrawingIcon className="icon-menu-actions-item icon-menu-actions-item-clear" />,
      whiteboard: <WhiteboardIcon className="icon-menu-actions" />,
      WhiteboardOn: <WhiteboardIcon className="icon-menu-actions" />,
      WhiteboardOff: <CloseIcon className="icon-menu-actions-item" />,
      WhiteboardNextSlide: <NextIcon />,
      live_object: <SelectActionLoIcon className="icon-menu-live_object" />,
      delete_all_live_objects: <DeleteAllLoIcon className="icon-menu-actions" />,
      place_live_objects: <PlaceLoIcon className="icon-menu-live_object" />
    }),
    []
  );
  const styledMenuItems = useMemo(() => {
    const menuToRender = menuItems
      .filter(menu => (slides.length ? menu : menu.key !== 'whiteboard'))
      .filter(item => (liveObjects.length ? item : item.key !== 'live_object'));
    return menuToRender.map(item => ({
      key: item.key,
      label: (
        <StyledMenuItem key={item.key} icon={actionIcons[item.key]}>
          {item.label}
        </StyledMenuItem>
      )
    }));
  }, [menuItems, actionIcons, isShowWhiteboardMenu, liveObjects, slides]);
  const menuActions = useMemo(
    () => ({
      items: styledMenuItems,
      onClick: handleMenuClick
    }),
    [styledMenuItems, handleMenuClick]
  );

  const menuItemsActions = useMemo(
    () => ({
      background: actionsConfig.backgrounds.map(item => ({
        key: item.id,
        label: <ImageActions key={item.id} src={baseActionPaths.actions_media_base_path + item.id + '_image.png'} alt={item.id} />
      })),
      character_moving_actions: actionsConfig.character_moving_actions.map(item => ({
        key: item.id,
        label: <ImageActions key={item.id} src={baseActionPaths.actions_media_base_path + item.icon + '.png'} alt={item.id} />
      })),
      character_actions: actionsConfig.character_actions.map(item => ({
        key: item.id,
        label: <ImageActions key={item.id} src={baseActionPaths.actions_media_base_path + item.icon + '.png'} alt={item.id} />
      })),
      awards: actionsConfig.awards.map(item => ({
        key: item.id,
        label: <ImageActions key={item.id} src={baseActionPaths.actions_media_base_path + item.icon + '.png'} alt={item.id} />
      })),
      effects: actionsConfig.effects.map(item => ({
        key: item.id,
        label: <ImageActions key={item.id} src={baseActionPaths.actions_media_base_path + item.icon + '.png'} alt={item.id} />
      })),
      drawing: actionsConfig.drawing.map(item => {
        return {
          key: item,
          label: (
            <StyledDrawingItem className={item}>
              {actionIcons[item as string]}
              {ActionButtonEnum[item as keyof typeof ActionButtonEnum]}
            </StyledDrawingItem>
          )
        };
      }),

      whiteboard: actionsConfig.whiteboard
        .map(item => {
          return {
            key: item,
            label: (
              <StyledDrawingItem className={`${item} stroke-none`}>
                {actionIcons[item as string]}
                {ActionButtonEnum[item as keyof typeof ActionButtonEnum]}
              </StyledDrawingItem>
            )
          };
        })
        .filter(item => {
          if (isShowOnlyWhiteboardOff) {
            return item.key === 'WhiteboardOff' || item.key === 'WhiteboardOn';
          } else if (slides.length && slides.length === 1) {
            return item.key !== 'WhiteboardNextSlide';
          } else {
            return item;
          }
        }),
      live_object: actionsConfig.live_object.map(item => {
        return {
          key: liveObjectWithId.find(lo => lo.name === item)?.object_id || item,
          label: (
            <StyledDrawingItem className={`${item}`}>
              {actionIcons[item as string]}
              {ActionButtonEnum[item as keyof typeof ActionButtonEnum]}
            </StyledDrawingItem>
          )
        };
      })
    }),
    [baseActionPaths, actionsConfig, actionIcons, isShowOnlyWhiteboardOff, slides]
  );

  const updateBlockActions = (
    prev: { [key: string]: WhiteboardPreviewItems[] },
    blockId: string,
    key: string,
    actionType: string,
    index: number
  ): { [key: string]: WhiteboardPreviewItems[] } => {
    const currentBlockActions: WhiteboardPreviewItems[] = prev[`${blockId}`] || [];
    const actionIndex = currentBlockActions.findIndex(action => action.dropdownKey === `${actionType}-${index}`);

    const updatedActions: WhiteboardPreviewItems[] =
      actionIndex !== -1
        ? currentBlockActions.map((action, idx) =>
            idx === actionIndex
              ? { action: key, blockId: blockId, slideIdx: index, dropdownKey: `${actionType}-${index}` }
              : action
          )
        : [...currentBlockActions, { action: key, blockId: blockId, slideIdx: index, dropdownKey: `${actionType}-${index}` }];

    return {
      ...prev,
      [blockId]: updatedActions
    };
  };

  const updateLoActionsItem = ({
    prev,
    blockId,
    live_object_id,
    dropdownKey,
    uniq_id,
    actionType
  }: {
    prev: { [key: string]: LoItem[] };
    blockId: string;
    live_object_id: string;
    dropdownKey: string;
    uniq_id: string;
    actionType: string;
  }): { [key: string]: LoItem[] } => {
    const currentBlockActions: LoItem[] = prev[blockId] || [];
    const actionIndex = currentBlockActions.findIndex(action => action.dropdownKey === dropdownKey);

    const updatedActions: LoItem[] =
      actionIndex !== -1
        ? currentBlockActions.map((action, idx) =>
            idx === actionIndex
              ? {
                  dropdownKey: dropdownKey,
                  live_object_id: live_object_id || '',
                  position_x: action.position_x || 0.35,
                  position_y: action.position_y || 0,
                  size: action.size || 0.3,
                  uniq_id: action.uniq_id ? action.uniq_id : uniq_id,
                  action_type: actionType
                }
              : action
          )
        : [
            ...currentBlockActions,
            {
              dropdownKey: dropdownKey,
              live_object_id: live_object_id,
              position_x: 0.35,
              position_y: 0,
              size: 0.3,
              uniq_id: uniq_id,
              action_type: actionType
            }
          ];

    return {
      ...prev,
      [blockId]: updatedActions
    };
  };

  const updatePaintActionToPreview = ({
    prev,
    dropdownKey,
    blockId,
    key
  }: {
    prev: { [key: string]: IPaintAction[] };
    dropdownKey: string;
    blockId: string;
    key: string;
  }): { [key: string]: IPaintAction[] } => {
    const currentBlockActions: IPaintAction[] = prev[blockId] || [];
    const actionIndex = currentBlockActions.findIndex(action => action.dropdownKey === dropdownKey);

    const updatedActions: IPaintAction[] =
      actionIndex !== -1
        ? currentBlockActions.map((action, idx) =>
            idx === actionIndex
              ? {
                  dropdownKey: dropdownKey,
                  action_type: key
                }
              : action
          )
        : [
            ...currentBlockActions,
            {
              dropdownKey: dropdownKey,
              action_type: key
            }
          ];

    return {
      ...prev,
      [blockId]: updatedActions
    };
  };

  const handleActionClick = useCallback(
    (actionType: string, key: string, index: number) => {
      if (actionType !== 'drawing') {
        setPreviewConfig(prev => {
          return {
            ...prev,
            [actionType]: key
          };
        });
      }

      if (actionType === 'whiteboard') {
        setWhiteboardEditableBlock(prev => updateBlockActions(prev, blockId, key, actionType, index));
        setWhiteboardPreview(prev => updateBlockActions(prev, blockId, key, actionType, index));
      }

      if (actionType === 'whiteboard' && key === 'WhiteboardOn' && !lastSavedCharacterPosition) {
        setLastSavedCharacterPosition((previewConfig['character_moving_actions'] as string) || 'default_position');
      }

      if (actionType === 'character_moving_actions' && whiteboardIconIdx < 0 && slides.length) {
        setLastSavedCharacterPosition('');
      }

      if (actionType === 'live_object' && key === 'delete_all_live_objects') {
        setLoToPreview(prev =>
          updateLoActionsItem({
            actionType: key,
            blockId: blockId,
            dropdownKey: `live_object-${index}`,
            live_object_id: '',
            prev: prev,
            uniq_id: ''
          })
        );
      }

      if (actionType === 'drawing') {
        setPaintToPreview(prev =>
          updatePaintActionToPreview({
            blockId: blockId,
            dropdownKey: `drawing-${index}`,
            key: key,
            prev
          })
        );
      }

      setNewActions(prev => {
        const exists = prev.some(action => action.dropdownKey === `${actionType}-${index}`);
        if (exists) {
          return prev.map((action, idx) => {
            if (action.dropdownKey === `${actionType}-${index}`) {
              return { action_type: actionType, id: key, dropdownKey: `${actionType}-${index}` };
            }
            return action;
          });
        } else {
          return [
            ...prev,
            {
              action_type: actionType,
              id: key,
              dropdownKey: `${actionType}-${index}`
            }
          ];
        }
      });
    },
    [setNewActions, setPreviewConfig, previewConfig, lastSavedCharacterPosition, blockId]
  );

  const handleLoActionClick = useCallback(
    (live_object_id: string, dropdownKey: string, actionType: string) => {
      const uniqueId = uuidv4();
      setNewActions(prev =>
        prev.map(item => {
          return item.dropdownKey === dropdownKey
            ? {
                ...item,
                position_x: 0.35,
                position_y: 0,
                size: 0.3,
                uniq_id: item.uniq_id ? item.uniq_id : uniqueId,
                live_object_id: live_object_id
              }
            : item;
        })
      );
      setLoToPreview(prev => {
        return updateLoActionsItem({ prev, blockId, live_object_id, dropdownKey, uniq_id: uniqueId, actionType });
      });
    },
    [newActions]
  );

  const renderCombinedMenu = useCallback(
    (index: number) => {
      const combinedMenuItems = [
        {
          title: 'Awards',
          items: menuItemsActions.awards,
          onClick: (key: string) => handleActionClick('awards', key, index)
        },
        {
          title: 'Effects',
          items: menuItemsActions.effects,
          onClick: (key: string) => handleActionClick('effects', key, index)
        }
      ];
      return {
        items: combinedMenuItems.map(({ title, items, onClick }, index) => ({
          key: `menu-${index}`,
          label: (
            <div className="overly-menu-note-items">
              <StyledTitleAction>{title}</StyledTitleAction>
              <StyledAwardsList className="awards-list">
                {items.map((item, i) => (
                  <StyledAwardsItem
                    className={`${item.key} awards-item`}
                    key={`item-${index}-${i}`}
                    onClick={() => onClick(item.key)}
                  >
                    {item.label}
                  </StyledAwardsItem>
                ))}
              </StyledAwardsList>
            </div>
          )
        }))
      };
    },
    [handleActionClick, menuItemsActions.awards, menuItemsActions.effects]
  );

  const menuItemsLoActions = useMemo(() => {
    if (!liveObjects.length) return [];
    return liveObjects.map(item => ({
      key: liveObjectWithId?.find(lo => lo.name === item)?.object_id || item,
      label: <ImageActions key={item} src={`${liveObjectsBasePath}${item}.png`} alt={item} />
    }));
  }, [liveObjectsBasePath, liveObjects]);

  const renderLoMenuActionsItems = useCallback(
    (dropdownKey: string, idx: number, actionType: string): MenuProps | undefined => {
      if (!menuItemsLoActions.length) return undefined;

      const itemsWithButton = [
        ...menuItemsLoActions.map((item, index) => ({
          key: `live_objects-${index}`,
          label: (
            <StyledActionsItem
              className={`${item.key} lo-image`}
              onClick={() => {
                handleLoActionClick(item.key, dropdownKey, actionType);
              }}
            >
              {item.label}
            </StyledActionsItem>
          )
        })),
        {
          key: 'button',
          label: (
            <div
              className="menu-button"
              onClick={() => {
                setCurrentSection(Sections.LiveObjects);
              }}
            >
              <StyledButtonOl className="btn-orange">Add other LO</StyledButtonOl>
            </div>
          )
        }
      ];

      return { items: itemsWithButton };
    },
    [menuItemsLoActions, handleActionClick, newActions, handleLoActionClick]
  );

  const renderMenuActionsItems = useCallback(
    (action_id: IActionsType, idx: number): MenuProps | undefined => {
      switch (action_id) {
        case 'backgrounds':
          return {
            items: menuItemsActions.background.map((item, index) => ({
              key: `background-${index}`,

              label: (
                <StyledActionsItem
                  className={item.key}
                  onClick={() => {
                    handleActionClick('backgrounds', item.key, idx);
                  }}
                >
                  {item.label}
                </StyledActionsItem>
              )
            }))
          };
        case 'character_moving_actions':
          return {
            items: menuItemsActions.character_moving_actions.map((item, index) => ({
              key: `character_moving_actions-${index}`,
              disabled: whiteboardIconIdx >= 0 && !!slides.length && item.key !== 'PositionLeft' && item.key !== 'PositionRight',
              label: (
                <StyledActionsItem
                  className={`${item.key} ${whiteboardIconIdx >= 0 && !!slides.length && item.key !== 'PositionLeft' && item.key !== 'PositionRight' && 'disabled'}`}
                  onClick={() => handleActionClick('character_moving_actions', item.key, idx)}
                >
                  {item.label}
                </StyledActionsItem>
              )
            }))
          };
        case 'character_actions':
          return {
            items: menuItemsActions.character_actions.map((item, index) => {
              return {
                key: `character_actions-${index}`,
                label: (
                  <StyledActionsItem className={item.key} onClick={() => handleActionClick('character_actions', item.key, idx)}>
                    {item.label}
                  </StyledActionsItem>
                )
              };
            })
          };

        case 'awards':
          return renderCombinedMenu(idx);
        case 'drawing':
          return {
            items: menuItemsActions.drawing.map((item, index) => ({
              key: `drawing-${index}`,
              label: <div onClick={() => handleActionClick('drawing', item.key, idx)}>{item.label}</div>
            }))
          };
        case 'whiteboard':
          return {
            items: menuItemsActions.whiteboard.map((item, index) => ({
              key: `whiteboard-${index}`,
              label: <div onClick={() => handleActionClick('whiteboard', item.key, idx)}>{item.label}</div>
            }))
          };
        case 'live_object':
          return {
            items: menuItemsActions.live_object.map((item, index) => ({
              key: `live_object-${index}`,
              label: <div onClick={() => handleActionClick('live_object', item.key, idx)}>{item.label}</div>
            }))
          };
        default:
          return undefined;
      }
    },
    [menuItemsActions, handleActionClick, renderCombinedMenu, whiteboardIconIdx, slides]
  );

  return { menuActions, actionIcons, renderMenuActionsItems, renderLoMenuActionsItems, handleActionClick };
};
