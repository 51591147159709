import styled, { css } from 'styled-components';
import headerImage from '../../assets/images/landing_header.png';
import { Input, Select } from 'antd';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  gap: 48px;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  background: url(${headerImage}) no-repeat center center;
  background-size: cover;
  flex-shrink: 0;

  @media (max-width: 1280px) {
    height: 297px;
  }

  @media (max-width: 1030px) {
    height: 200px;
  }
`;

export const HeaderText = styled.p`
  color: #fff;
  font-size: 64px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 95px;

  @media (max-width: 1280px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media (max-width: 700px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const PageContent = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 48px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--bg-basic);
  border-radius: 24px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 300px));
  justify-content: center;
  gap: 20px;
`;

export const AgreementContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: center;

  a {
    text-decoration: underline;
  }

  @media (max-width: 476px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SlidesContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 224px);
  justify-content: center;
  gap: 20px;
`;

export const SlideItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  img {
    background-color: var(--light-violet);
    border-radius: 16px;
    height: 150px;
  }
`;

export const FooterImageContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;

  img {
    max-width: 100%;
    height: auto;
    max-height: 250px;
    object-fit: cover;
  }

  @media (max-width: 1280px) {
    max-width: 660px;
  }

  @media (max-width: 500px) {
    padding: 0;
    img {
      height: 135px;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-basic);

  .footer-text {
    font-size: 24px;
    font-weight: 800;
    color: var(--text-red);
  }

  @media (max-width: 700px) {
    .footer-text {
      font-size: 16px;
    }
  }
`;

export const StyledFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 16px;
  align-items: end;
  margin-bottom: 8px;
`;
