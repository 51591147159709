import React, { useEffect, useState } from 'react';
import { PopUpWrapper, StyledPopUpContent, StyledPopupHeader } from '../ScriptStyles';
import { message, Space, Spin, Typography } from 'antd';

import CloseIcon from '../../../assets/close.png';
import styled from 'styled-components';

import { IAccessControl } from '../../../types';
import { getResource, updateResource } from '../../../api/apiWorker';
import { EditBtn } from '../../../assets/styles/Styled';
import { LoadingOutlined } from '@ant-design/icons';
import { ISharingList, SharedForm } from '../../../components/SharedForm/SharedForm';

const PopupContent = styled(StyledPopUpContent)`
  max-width: 835px;
  margin: 0 15px;

  @media (max-width: 450px) {
    max-width: unset;
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: 0;
    justify-content: flex-start;
    overflow-y: auto;
    padding: 0;
  }
`;

const PopupHeader = styled(StyledPopupHeader)`
  @media (max-width: 450px) {
    padding: 15px 15px 0;
  }
`;

const StyledPopupBody = styled.div`
  width: 100%;
  @media (max-width: 450px) {
    padding: 15px;
    background: var(--light-gray);
    flex: 1;
  }
`;

export const ButtonSubmit = styled(EditBtn)`
  margin: 14px auto 0;
  background: linear-gradient(180deg, #ffd08a 0%, #ed6400 100%);
`;
interface PopupSharedFolderProps {
  setShowPopupSharedFolder: React.Dispatch<React.SetStateAction<boolean | null>>;
  resourceId: string;
  fetchScripts?: () => Promise<void>;
}

export const PopupSharedFolder: React.FC<PopupSharedFolderProps> = ({ setShowPopupSharedFolder, fetchScripts, resourceId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingSharingState, setLoadingSharingState] = useState(false);
  const [allowDuplicate, setAllowDuplicate] = useState(false);

  const [sharingList, setSharingList] = useState<ISharingList>({
    organizations: [],
    users: []
  });

  useEffect(() => {
    initSharedList();
  }, []);

  const initSharedList = async () => {
    try {
      const resource = await getResource(resourceId);
      const { access_control } = resource;
      const usersAccess = access_control?.filter(control => control.entity_type === 'user');
      const organizationsAccess = access_control?.filter(control => control.entity_type === 'organization');
      const organizationItems =
        organizationsAccess?.map(org => ({
          value: org.entity_id,
          label: org.name || '',
          restriction: org.write_access ? 'edit' : 'view'
        })) ?? [];
      const userItems =
        usersAccess?.map(user => ({
          value: user.entity_id,
          label: user.name || '',
          restriction: user.write_access ? 'edit' : 'view'
        })) ?? [];

      setSharingList({
        users: userItems,
        organizations: organizationItems
      });
    } catch (e) {
      message.error('Fetch resource failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSharedFolder = async () => {
    setLoadingSharingState(true);
    const accessControlUsers: IAccessControl[] = sharingList.users.map(item => ({
      entity_id: item.value,
      entity_type: 'user',
      write_access: item.restriction !== 'view'
    }));
    const accessControlOrganizations: IAccessControl[] = sharingList.organizations.map(item => ({
      entity_id: item.value,
      entity_type: 'organization',
      write_access: item.restriction !== 'view'
    }));
    try {
      await updateResource({
        resource_id: resourceId,
        access_control: [...accessControlUsers, ...accessControlOrganizations]
      });
      fetchScripts && fetchScripts();
      setShowPopupSharedFolder(null);
      message.success('Sharing folder success');
    } catch (e) {
      message.error('Sharing folder failed');
    } finally {
      setLoadingSharingState(false);
    }
  };

  return (
    <PopUpWrapper>
      <PopupContent>
        <PopupHeader>
          <Typography.Text className="text-title fnt-open-sans" style={{ fontSize: '24px', fontWeight: '700' }}>
            Sharing Options
          </Typography.Text>

          <div className="close" onClick={() => setShowPopupSharedFolder(null)}>
            <img src={CloseIcon} alt={''} />
          </div>
        </PopupHeader>
        <StyledPopupBody>
          <Spin spinning={isLoading}>
            <SharedForm
              setSharingList={setSharingList}
              // setAllowDuplicate={setAllowDuplicate}
              allowDuplicate={allowDuplicate}
              sharingList={sharingList}
            />
            <ButtonSubmit
              type="button"
              disabled={(!sharingList.organizations.length && !sharingList.users.length) || loadingSharingState}
              onClick={handleSharedFolder}
            >
              <Space>
                {' '}
                Share Folder
                <Spin spinning={loadingSharingState} indicator={<LoadingOutlined spin />} size="small" />
              </Space>
            </ButtonSubmit>
          </Spin>
        </StyledPopupBody>
      </PopupContent>
    </PopUpWrapper>
  );
};
