import { useMediaQuery } from 'react-responsive';
import { TabsProps } from 'antd';
import { Sections } from '../../../types';

export const useResponsiveTabs = (items: TabsProps['items'], currentKey: Sections) => {
  const isDesktop = useMediaQuery({ minWidth: 840 });

  return items?.map(item => ({
    ...item,
    label: isDesktop || item.key === currentKey ? item.label : ''
  }));
};
