import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ active: boolean }>`
  height: 48px;
  width: 70px;
  flex-shrink: 0;
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 6px;
  border: 2px solid ${props => (props.active ? 'var(--primary-color);' : 'var(--light-violet)')};
  border-radius: 30px;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    ${props =>
      props.active
        ? 'filter: invert(80%) sepia(79%) saturate(3492%) hue-rotate(247deg) brightness(96%) contrast(106%)'
        : 'filter: invert(45%) sepia(6%) saturate(1340%) hue-rotate(193deg) brightness(87%) contrast(89%);'}
  }
`;

interface Props {
  name: string;
  imageUrl: string;
  active: boolean;
  onClick: (name: string) => void;
}

export const CategoryItem: React.FunctionComponent<Props> = ({ name, imageUrl, active, onClick }) => {
  return (
    <Container active={active} onClick={() => onClick(name)}>
      <img alt={name} src={imageUrl} />
    </Container>
  );
};
