import React, { FC } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { LoaderContent, LoaderWrapperModal } from '../assets/styles/Styled';

interface LoaderProps {
  loading: boolean;
}
const Loader: FC<LoaderProps> = ({ loading }) => {
  return (
    <LoaderWrapperModal>
      <LoaderContent>
        <ClipLoader color={'#713FD'} loading={loading} size={40} />
        <p>Connecting</p>
      </LoaderContent>
    </LoaderWrapperModal>
  );
};

export default Loader;
