import React, { useEffect, useState } from 'react';
import { ICharacters } from '../../types';
import { Dropdown, MenuProps, Space, Typography } from 'antd';
import CharacterIcon from '../../assets/images/characters/character_icon.png';
import styled from 'styled-components';
import { DropdownIcon } from '../../assets/icons';
import { useAppContext } from '../../context/AppContext';

interface ICharactersListItemProps {
  character: ICharacters;
  setShowPopupEdit: React.Dispatch<React.SetStateAction<ICharacters | null>>;
  handleUnavailable: ({ id }: { id: string }) => Promise<void>;
}

const ListItemContainer = styled.div`
  width: 100%;
  padding: 20px 16px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
`;

const StyledIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  background: #d9d9d9;
  & > img {
    width: 125%;
    height: 125%;
    object-fit: cover;
    transform: translate(-10%, -4%);
  }
`;

const CharacterName = styled.span`
  font-size: 14px;
  font-weight: 800;
  line-height: 20.79px;
`;

const CharacterAvailable = styled.div`
  font-family: 'Mulish', sans-serif;

  font-size: 12px;
  font-weight: 500;
  line-height: 15.06px;
  letter-spacing: 0.035em;
  color: var(--primary-color);
`;

enum CharactersAvailability {
  'no_one' = 'Available for no one',
  'everyone' = 'Available for everyone'
}

export const CharactersListItem: React.FC<ICharactersListItemProps> = ({ character, setShowPopupEdit, handleUnavailable }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const { organizations, organizationsLoading } = useAppContext();

  const getImageByName = async (imageName: string) => {
    try {
      const image = await import(`../../assets/images/characters/${imageName}.png`);
      return image.default;
    } catch (error) {
      console.error('Error loading image:', error);
      return null;
    }
  };

  useEffect(() => {
    const loadImage = async () => {
      const src = await getImageByName(character.id || 'default_image');
      setImageSrc(src);
    };

    loadImage();
  }, [character.id]);
  const menuItems: MenuProps['items'] = [
    {
      label: <Typography className="normal-16 text-secondary">Edit Access Options</Typography>,
      key: 'edit',
      onClick: () => setShowPopupEdit(character)
    },
    {
      label: <Typography className="normal-16 text-red">Make Unavailable</Typography>,
      key: 'unavailable',
      onClick: () => handleUnavailable({ id: character.id })
    }
  ];

  const renderCompanies = (characterOrganizations: string[]) => {
    const filteredOrganizations = organizations.filter(item => characterOrganizations.includes(item.organization_id));
    if (!filteredOrganizations.length) return 'one one';
    if (filteredOrganizations.length > 0 && filteredOrganizations.length === 1) {
      return `${filteredOrganizations[0].organization_name} organization`;
    } else if (filteredOrganizations.length > 0 && filteredOrganizations.length === 2) {
      return `${filteredOrganizations[0].organization_name} and ${filteredOrganizations[1].organization_name} organizations`;
    } else if (filteredOrganizations.length > 0 && filteredOrganizations.length === 3) {
      return `${filteredOrganizations[0].organization_name}, ${filteredOrganizations[1].organization_name} and ${filteredOrganizations[2].organization_name} organizations`;
    } else {
      return `${filteredOrganizations[0].organization_name}, ${filteredOrganizations[1].organization_name}, ${filteredOrganizations[2].organization_name} and other ${filteredOrganizations.length} organizations`;
    }
  };

  return (
    <ListItemContainer key={character.id}>
      <Space size={8} direction="vertical">
        <Space size={8} direction="horizontal">
          <StyledIconWrapper>
            <img src={imageSrc || CharacterIcon} alt="character" />
          </StyledIconWrapper>
          <CharacterName className="primary-text">{character.name || 'no name'}</CharacterName>
        </Space>
        {character.availability !== 'specific' ? (
          <CharacterAvailable>
            {CharactersAvailability[character.availability as keyof typeof CharactersAvailability]}
          </CharacterAvailable>
        ) : (
          <CharacterAvailable>Available for {renderCompanies(character.organizations)}</CharacterAvailable>
        )}
      </Space>

      <Dropdown trigger={['click']} menu={{ items: menuItems }}>
        <DropdownIcon className="hint-color cursor-pointer" />
      </Dropdown>
    </ListItemContainer>
  );
};
