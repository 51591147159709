import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from 'antd';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const NotFoundPage = () => {
  return (
    <Container>
      <Typography className="extrabold-24 text-white">404 - Page Not Found</Typography>
      <Typography className="normal-16  text-white">The page you are looking for doesn't exist.</Typography>
      <Typography className="normal-16  text-white">
        Go back to the <Link to="/admin">Home</Link>.
      </Typography>
    </Container>
  );
};

export default NotFoundPage;
