import React from 'react';
import { TermsOfUsePrivacyPolicyWrapper } from '../assets/styles/Styled';

const TermsOfUsePrivacyPolicy = () => {
  return (
    <TermsOfUsePrivacyPolicyWrapper>
      <p className="terms-of-use-privacy">By continuing you agree with</p>
      <p className="terms-of-use-privacy">
        <a href="https://family-fresh.vercel.app/en/privacy-policy">Privacy Policy</a>
        <a href="https://partners.mapanda.com/en/terms-and-conditions">Terms of Use</a>
      </p>
    </TermsOfUsePrivacyPolicyWrapper>
  );
};

export default TermsOfUsePrivacyPolicy;
