import React from 'react';
import styled from 'styled-components';
import { useScriptForm } from '../../../ScriptCreateStore';
import { Popconfirm, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const Container = styled.div<{ centered: boolean }>`
  position: relative;
  max-height: 190px;
  overflow-y: auto;
  padding: 10px 70px 10px 16px;
  width: 100%;
  display: flex;
  justify-content: ${props => (props.centered ? 'center' : 'start')};
  align-items: flex-start;
  background-color: var(--light-gray);
  border-radius: 32px;

  .close-icon-absolute-main {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 22px;
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 4px;
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .close-icon-absolute {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 1;
    background-color: #fff;
    border-radius: 10px;
    padding: 2px;
    font-size: 20px;
  }
`;

interface Props {
  path: string;
}

export const LiveObjectPreviewBox: React.FunctionComponent<Props> = ({ path }) => {
  const {
    formValues: { live_objects },
    setFormValues
  } = useScriptForm();
  const amountSelected = live_objects.length;

  const handleItemRemove = (name: string) => {
    const updatedItems = live_objects.filter(item => item !== name);
    setFormValues(prev => ({ ...prev, live_objects: updatedItems }));
  };

  return (
    <Container centered={!amountSelected}>
      {!amountSelected ? (
        <Typography style={{ justifySelf: 'center' }} className="extrabold-16 description-solid">
          Select LO from the list below
        </Typography>
      ) : (
        <>
          <ImagesWrapper>
            <Typography className="extrabold-16 description-solid flex-shrink-0">{amountSelected} Selected</Typography>

            {live_objects.map(item => (
              <ImageContainer key={item}>
                <img alt={item} src={`${path + item}.png`} />
                <CloseOutlined
                  className="close-icon-absolute description-solid cursor-pointer"
                  onClick={() => handleItemRemove(item)}
                />
              </ImageContainer>
            ))}
          </ImagesWrapper>
          <Popconfirm
            title="Are you sure you want clear items?"
            okText="Yes"
            okButtonProps={{ danger: true }}
            onConfirm={() => setFormValues(prev => ({ ...prev, live_objects: [] }))}
          >
            <CloseOutlined className="close-icon-absolute-main description-solid cursor-pointer" />
          </Popconfirm>
        </>
      )}
    </Container>
  );
};
