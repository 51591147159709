import React from 'react';
import { ActionButtonEnum, ActionEnum, IActionsType, NewActionsItem } from '../../../../../types';
import { Dropdown, MenuProps } from 'antd';
import styled from 'styled-components';
import { CloseCircleIcon } from '../../../../../assets/icons';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { ButtonAction, NotesBlock } from '../../sections.styled';

interface ISortableActionItems {
  blockId: string;
  idx: number;
  dropdownKey: string;
  item: string;
  openDropdowns: {
    [key: string]: boolean;
  };
  newActions: NewActionsItem[];
  liveObjects: string[];
  uid: string;
  renderMenuActionsItems: (action_id: IActionsType, idx: number) => MenuProps | undefined;
  handleOpenDropdown: (dropdownKey: string, flag: boolean) => void;
  setSelectedActionsItem: React.Dispatch<React.SetStateAction<string[]>>;
  renderIcon: (actionType: IActionsType, dropdownKey: string) => JSX.Element;
  renderIconAwardsEffects: (dropdownKey: string) => JSX.Element;
  renderTitle: (actionType: string, dropdownKey: string) => JSX.Element | ActionButtonEnum | undefined;
  renderTitleAwardsEffects: (dropdownKey: string) => JSX.Element;
  renderLoMenuActionsItems: (dropdownKey: string, idx: number, actionType: string) => MenuProps | undefined;
  renderIconItemsLo: (actionType: IActionsType, dropdownKey: string) => JSX.Element | undefined;
  renderTitleLo: (dropdownKey: string) => string | undefined;
  handleRemoveAction: (selectedActionIdx: number) => void;
}

const ArrowDownIcon = styled.div<{ open: boolean }>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  margin-left: 11px;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s;
`;
const StyledCloseIcon = styled(CloseCircleIcon)`
  position: absolute;
  top: 8px;
  right: 16px;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background: #ffe1b7;
  color: #f9b192;
  cursor: pointer;
  > svg {
    > rect {
      fill: #ffe1b7;
    }
  }
`;
export const SortableActionItems: React.FC<ISortableActionItems> = React.memo(
  ({
    idx,
    dropdownKey,
    item,
    blockId,
    openDropdowns,
    newActions,
    liveObjects,
    uid,
    renderMenuActionsItems,
    handleOpenDropdown,
    setSelectedActionsItem,
    renderIcon,
    renderIconAwardsEffects,
    renderTitle,
    renderTitleAwardsEffects,
    renderLoMenuActionsItems,
    renderIconItemsLo,
    renderTitleLo,
    handleRemoveAction
  }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: uid
    });
    const draggableStyle = {
      transform: `${CSS.Translate.toString(transform)} rotate(${isDragging ? '-3deg' : '0deg'})`,

      transition,
      zIndex: isDragging ? 1000 : 'auto',
      opacity: isDragging ? 0.5 : 1
    };
    return (
      <NotesBlock key={dropdownKey + blockId} ref={setNodeRef} style={{ ...draggableStyle, minHeight: 'unset' }} {...attributes}>
        <div style={{ cursor: 'ns-resize' }} {...listeners}>
          {ActionEnum[item as keyof typeof ActionEnum]}
        </div>
        <Dropdown
          key={dropdownKey}
          menu={renderMenuActionsItems(item as IActionsType, idx)}
          placement="top"
          overlayClassName={`overly-menu-note-items ${'overly-menu-note-items-' + item} ${item === 'drawing' || item === 'whiteboard' || item === 'live_object' ? 'overly-menu-note-items-columns' : ''}`}
          className="drop-menu"
          trigger={['click']}
          open={!!openDropdowns[dropdownKey]}
          onOpenChange={flag => handleOpenDropdown(dropdownKey, flag)}
        >
          <ButtonAction
            key={'btn-menu'}
            onClick={() => {
              let effectsAction = '';
              const currentAction = newActions?.find(item => item.dropdownKey === dropdownKey)?.id || '';
              if (item === 'awards') {
                effectsAction = newActions.find(item => item.dropdownKey === dropdownKey.replace('awards', 'effects'))?.id || '';
              }
              setSelectedActionsItem(prev => [...prev, currentAction, effectsAction]);
            }}
          >
            {item !== 'awards' && renderIcon(item as IActionsType, dropdownKey)}
            {item === 'awards' && renderIconAwardsEffects(dropdownKey)}
            {item !== 'awards' && renderTitle(item, dropdownKey)}
            {item === 'awards' && renderTitleAwardsEffects(dropdownKey)}
            <ArrowDownIcon open={!!openDropdowns[dropdownKey]} />
          </ButtonAction>
        </Dropdown>
        {!!liveObjects.length &&
          !!newActions.length &&
          newActions.find(action => action.dropdownKey === dropdownKey)?.id === 'place_live_objects' && (
            <Dropdown
              key={`${dropdownKey} - drop-lo`}
              menu={renderLoMenuActionsItems(dropdownKey, idx, item as IActionsType)}
              placement="top"
              overlayClassName={`overly-menu-note-items overly-menu-note-items-live_object-items`}
              className="drop-menu-lo"
              trigger={['click']}
              open={!!openDropdowns[`${dropdownKey}-drop-lo`]}
              onOpenChange={flag => handleOpenDropdown(`${dropdownKey}-drop-lo`, flag)}
            >
              <ButtonAction
                key={`btn-menu-item-${dropdownKey}`}
                onClick={() => {
                  const currentAction = newActions.find(item => item.dropdownKey === dropdownKey)?.live_object_id || '';
                  setSelectedActionsItem(prev => [...prev, currentAction]);
                }}
              >
                {renderIconItemsLo('place_live_objects' as IActionsType, dropdownKey)}
                {renderTitleLo(dropdownKey)}
                <ArrowDownIcon open={!!openDropdowns[`${dropdownKey}-drop-lo`]} />
              </ButtonAction>
            </Dropdown>
          )}
        <StyledCloseIcon onClick={() => handleRemoveAction(idx)} />
      </NotesBlock>
    );
  }
);
