import React, { useMemo, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { UnionIcon } from '../../../../../assets/icons';
import { IActionsConfigPositions, LiveObjectsIcons, LoItem, LoItemToPreview } from '../../../../../types';
import { Typography } from 'antd';
import whiteboardImage from '../../../../../assets/images/whiteboard.png';
import { Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd';
import useMedia from '../../../../../hooks/useMedia';
import pencilsImage from '../../../../../assets/images/Pensils.png';
interface IconWrapperProps {
  top: string;
  left: string;
  scale: string;
}

const StyledPreview = styled.div`
  position: relative;
  max-width: 320px;
  width: 300px;
  height: 225px;
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto 15px;
  z-index: 1;

  @media (min-width: 768px) and (max-width: 1024px) {
    & {
      width: 220px;
      height: 165px;
    }
  }
  @media (max-width: 767px) {
    & {
      max-width: 320px;
      width: 320px;
      height: 240px;
      margin-bottom: 8px;
    }
  }
`;

const IconWrapper = styled.div<IconWrapperProps>`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  z-index: 3;
  > span {
    width: 80px;
    height: auto;
    transform: ${props => props.scale};
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 60px;
    }
    @media (max-width: 767px) {
      width: 80px;
    }
  }
`;

const StyledNotesTitle = styled(Typography.Paragraph)`
  color: var(--light-description);
  font-family: 'Mulish', sans-serif !important;
  font-size: 20px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  margin-bottom: 8px !important;

  @media (max-width: 767px) {
    & {
      font-size: 16px;
    }
  }
`;

const WhiteboardBlock = styled.div`
  position: absolute;
  top: 14px;
  left: calc(50% - 124px);
  width: 248px;
  height: 168px;
  background-image: url(${whiteboardImage});
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 2;

  @media (min-width: 768px) and (max-width: 1024px) {
    & {
      width: 182px;
      height: 122px;
      left: calc(50% - 91px);
    }
  }
  @media (max-width: 767px) {
    & {
      left: calc(50% - 133px);
      width: 266px;
      height: 178px;
    }
  }
`;

const WhiteboardImgWrapper = styled.div`
  position: relative;
  width: 225px;
  height: 128px;
  top: 19px;
  left: 12px;
  overflow: hidden;

  @media (min-width: 768px) and (max-width: 1024px) {
    & {
      width: 165px;
      height: 94px;
      top: 14px;
      left: 8px;
    }
  }
  @media (max-width: 767px) {
    & {
      width: 239px;
      height: 137px;
      left: 12px;
    }
  }
`;

const LoWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
`;

const slideAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  animation: ${slideAnimation} 0.3s ease-in-out forwards;
`;

const StyledImgPencils = styled.img`
  position: absolute;
  bottom: 2px;
  width: 182px;
  left: calc(50% - 90px);
  transform: scale(1.5);
  z-index: 12;
  @media (min-width: 768px) and (max-width: 1024px) {
    & {
      width: 128px;
      left: calc(50% - 63px);
    }
  }
  @media (max-width: 767px) {
    & {
      width: 182px;
      left: calc(50% - 90px);
    }
  }
`;

interface INotesPreviewProps {
  position?: IActionsConfigPositions['icon'];
  background?: string;
  basePath: string;
  slides: string[];
  whiteboardSlideIdx: number;
  loPreview: LoItem[];
  liveObjectsBasePath: string;
  isShowPencils: boolean;
  liveObjectsIcons: LiveObjectsIcons;
  activeLoToPreview: string[];
  setLoToPreview: React.Dispatch<React.SetStateAction<LoItemToPreview>>;
}
interface Position {
  newPositionX: number;
  newPositionY: number;
}
interface LoRefSize {
  newSize: number;
}
export const NotesPreview: React.FC<INotesPreviewProps> = ({
  position = 'default_position',
  basePath,
  background,
  slides,
  whiteboardSlideIdx,
  loPreview,
  liveObjectsBasePath,
  isShowPencils,
  liveObjectsIcons,
  activeLoToPreview,
  setLoToPreview
}) => {
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const { isMobile, isTablet, isDesktop } = useMedia({ mobileMaxWidth: 767, tabletMinWidth: 768, tabletMaxWidth: 1024 });
  const localPositionRef = useRef<Record<string, Position>>({});
  const localLoSizeRef = useRef<Record<string, LoRefSize>>({});

  const { parentWidth, parentHeight } = useMemo(() => {
    if (isMobile) {
      return { parentWidth: 320, parentHeight: 240 };
    } else if (isTablet) {
      return { parentWidth: 220, parentHeight: 165 };
    } else if (isDesktop) {
      return { parentWidth: 300, parentHeight: 225 };
    } else {
      return { parentWidth: 300, parentHeight: 225 };
    }
  }, [isMobile, isTablet, isDesktop]);
  const maxRelativeSize = 0.9;
  const maxSizeLo = maxRelativeSize * parentHeight;

  const handleDragStop: RndDragCallback = (e, d) => {
    const index = loPreview.findIndex(item => item.uniq_id === d.node.id);
    if (index !== -1) {
      setLoToPreview(prev => {
        const newPrev = Object.keys(prev).reduce((acc, blockId) => {
          const updatedItems = prev[blockId].map(item => {
            if (item.uniq_id === d.node.id) {
              let denominatorX = parentWidth - d.node.offsetWidth;
              let denominatorY = parentHeight - d.node.offsetHeight;

              let newPositionX = 0;
              let newPositionY = 0;

              if (denominatorX !== 0) {
                newPositionX = parseFloat((d.x / parentWidth).toFixed(2));
                newPositionX = Math.max(0, Math.min(newPositionX, 1));
              }
              if (denominatorY !== 0) {
                newPositionY = parseFloat((d.y / parentHeight).toFixed(2));
                newPositionY = Math.max(0, Math.min(newPositionY, 1));
              }
              localPositionRef.current[d.node.id] = { newPositionX: newPositionX, newPositionY: newPositionY };

              return {
                ...item,
                position_x: newPositionX,
                position_y: newPositionY
              };
            }
            return item;
          });

          acc[blockId] = updatedItems;
          return acc;
        }, {} as LoItemToPreview);
        // setActiveItem(null);

        return newPrev;
      });
    }
  };

  const handleResizeStop: RndResizeCallback = (e, direction, ref, delta, position) => {
    const index = loPreview.findIndex(item => item.uniq_id === ref.id);
    if (index !== -1) {
      setLoToPreview(prev => {
        const newPrev = Object.keys(prev).reduce((acc, blockId) => {
          const updatedItems = prev[blockId].map(item => {
            if (item.uniq_id === ref.id) {
              let denominatorX = parentWidth - ref.offsetWidth;
              let denominatorY = parentHeight - ref.offsetHeight;

              let newPositionX = 0;
              let newPositionY = 0;

              if (denominatorX !== 0) {
                newPositionX = parseFloat(((position.x || 0) / parentWidth).toFixed(2));
                newPositionX = Math.max(0, Math.min(newPositionX, 1));
              }

              if (denominatorY !== 0) {
                newPositionY = parseFloat(((position.y || 0) / parentHeight).toFixed(2));
                newPositionY = Math.max(0, Math.min(newPositionY, 1));
              }
              let newSize = parseFloat((ref.offsetWidth / parentWidth).toFixed(2));

              newSize = Math.max(0, Math.min(newSize, 1));
              localPositionRef.current[ref.id] = { newPositionX: newPositionX, newPositionY: newPositionY };
              localLoSizeRef.current[ref.id] = { newSize };
              return {
                ...item,
                position_x: newPositionX,
                position_y: newPositionY,
                size: newSize
              };
            }
            return item;
          });

          acc[blockId] = updatedItems;
          return acc;
        }, {} as LoItemToPreview);
        // setActiveItem(null);
        return newPrev;
      });
    }
  };
  const iconPosition = useMemo(() => {
    if (isDesktop) {
      return {
        default_position: {
          top: `calc(50% - 36px)`,
          left: 'calc(50% - 40px)',
          scale: 'scale(1)'
        },
        left_position: {
          top: whiteboardSlideIdx === -1 ? `calc(50% - 30px)` : `calc(50%)`,
          left: whiteboardSlideIdx === -1 ? `0` : '-3px',
          scale: 'scale(0.8)'
        },
        right_position: {
          top: whiteboardSlideIdx === -1 ? `calc(50% - 30px)` : `calc(50%)`,
          left: whiteboardSlideIdx === -1 ? `calc(100% - 79px)` : `calc(100% - 76px)`,
          scale: 'scale(0.8)'
        },
        dialogue_position: {
          top: `calc(100% - 103px)`,
          left: 'calc(50% - 105px)',
          scale: 'scale(2.2)'
        }
      };
    }
    if (isTablet) {
      return {
        default_position: {
          top: `calc(50% - 42px)`,
          left: 'calc(50% - 30px)',
          scale: 'scale(1)'
        },
        left_position: {
          top: whiteboardSlideIdx === -1 ? `calc(50% - 38px)` : `calc(50% - 12px)`,
          left: whiteboardSlideIdx === -1 ? '0' : '-3px',
          scale: 'scale(0.8)'
        },
        right_position: {
          top: whiteboardSlideIdx === -1 ? `calc(50% - 38px)` : `calc(50% - 12px)`,
          left: whiteboardSlideIdx === -1 ? `calc(100% - 61px)` : `calc(100% - 58px)`,
          scale: 'scale(0.8)'
        },
        dialogue_position: {
          top: `calc(100% - 91px)`,
          left: 'calc(50% - 76px)',
          scale: 'scale(2.2)'
        }
      };
    }

    return {
      default_position: {
        top: `calc(50% - 36px)`,
        left: 'calc(50% - 40px)',
        scale: 'scale(1.1)'
      },
      left_position: {
        top: whiteboardSlideIdx === -1 ? `calc(50% - 30px)` : `calc(50%)`,
        left: whiteboardSlideIdx === -1 ? `3px` : '0',
        scale: 'scale(0.9)'
      },
      right_position: {
        top: whiteboardSlideIdx === -1 ? `calc(50% - 30px)` : `calc(50%)`,
        left: whiteboardSlideIdx === -1 ? `calc(100% - 82px)` : `calc(100% - 79px)`,
        scale: 'scale(0.9)'
      },
      dialogue_position: {
        top: `calc(100% - 103px)`,
        left: 'calc(50% - 108px)',
        scale: 'scale(2.4)'
      }
    };
  }, [isMobile, isTablet, isDesktop, whiteboardSlideIdx]);

  const selectedSlide = useMemo(() => slides[whiteboardSlideIdx] || '', [slides, whiteboardSlideIdx]);

  return (
    <div>
      <StyledNotesTitle>Call Screen Preview</StyledNotesTitle>

      <StyledPreview
        style={{
          backgroundImage: `url(${basePath}${background}_image.png)`
        }}
      >
        <IconWrapper scale={iconPosition[position].scale} top={iconPosition[position].top} left={iconPosition[position].left}>
          <UnionIcon />
        </IconWrapper>
        {!!slides.length && whiteboardSlideIdx >= 0 && (
          <WhiteboardBlock>
            <WhiteboardImgWrapper>
              <Img key={selectedSlide} src={selectedSlide} alt={selectedSlide} />
            </WhiteboardImgWrapper>
          </WhiteboardBlock>
        )}

        {!!loPreview.length && (
          <LoWrapper>
            {loPreview.map((item, index) => {
              const itemWidth = (item.size || 0.3) * parentWidth;
              const itemWidthRef =
                localLoSizeRef.current[item.uniq_id]?.newSize && localLoSizeRef.current[item.uniq_id].newSize * parentWidth;
              const itemPositionXRef =
                localPositionRef.current[item.uniq_id]?.newPositionX &&
                Math.round(localPositionRef.current[item.uniq_id].newPositionX * parentWidth);
              const itemPositionYRef =
                localPositionRef.current[item.uniq_id]?.newPositionY &&
                Math.round(localPositionRef.current[item.uniq_id].newPositionY * parentHeight);
              const itemPositionX = Math.round((item.position_x || 0) * parentWidth);
              const itemPositionY = Math.round((item.position_y || 0) * parentHeight);
              const loIcon = liveObjectsIcons[item.live_object_id];
              const itemPath = `${liveObjectsBasePath}${loIcon}.png`;
              const isActiveLo = activeLoToPreview.some(lo => lo === item.uniq_id);
              return (
                <Rnd
                  key={item.uniq_id}
                  id={item.uniq_id}
                  size={{ width: itemWidthRef || itemWidth, height: itemWidthRef || itemWidth }}
                  position={{ x: itemPositionXRef || itemPositionX, y: itemPositionYRef || itemPositionY }}
                  onDragStop={handleDragStop}
                  onResizeStop={handleResizeStop}
                  lockAspectRatio={true}
                  bounds="parent"
                  onClick={() => (isActiveLo ? setActiveItem(item.uniq_id) : setActiveItem(null))}
                  onTouchStart={() => (isActiveLo ? setActiveItem(item.uniq_id) : setActiveItem(null))}
                  onResizeStart={() => (isActiveLo ? setActiveItem(item.uniq_id) : setActiveItem(null))}
                  onDragStart={() => (isActiveLo ? setActiveItem(item.uniq_id) : setActiveItem(null))}
                  style={{
                    border: activeItem === item.uniq_id ? '1px solid blue' : 'none',
                    touchAction: 'none',
                    zIndex: activeItem === item.uniq_id ? 10 : 3,
                    willChange: 'transform, width, height'
                  }}
                  resizeHandleClasses={{
                    bottomLeft:
                      isActiveLo && activeItem === item.uniq_id ? 'resize-corner-base resize-corner-base-bottom-left' : '',
                    bottomRight:
                      isActiveLo && activeItem === item.uniq_id ? 'resize-corner-base resize-corner-base-bottom-right' : '',
                    topLeft: isActiveLo && activeItem === item.uniq_id ? 'resize-corner-base resize-corner-base-top-left' : '',
                    topRight: isActiveLo && activeItem === item.uniq_id ? 'resize-corner-base resize-corner-base-top-right' : ''
                  }}
                  disableDragging={isActiveLo ? false : true}
                  enableResizing={
                    !isActiveLo
                      ? false
                      : {
                          bottom: false,
                          bottomLeft: true,
                          bottomRight: true,
                          left: false,
                          right: false,
                          top: false,
                          topLeft: true,
                          topRight: true
                        }
                  }
                  minWidth={20}
                  minHeight={20}
                  maxHeight={maxSizeLo}
                  maxWidth={maxSizeLo}
                >
                  <img
                    src={itemPath}
                    alt={item.live_object_id}
                    style={{ width: '100%', height: '100%', pointerEvents: 'none', objectFit: 'contain' }}
                  />
                  {isActiveLo && activeItem === item.uniq_id && (
                    <>
                      <div className="resize-corner top-left" />
                      <div className="resize-corner top-right" />
                      <div className="resize-corner bottom-left" />
                      <div className="resize-corner bottom-right" />
                    </>
                  )}
                </Rnd>
              );
            })}
          </LoWrapper>
        )}
        {isShowPencils && <StyledImgPencils src={pencilsImage}></StyledImgPencils>}
      </StyledPreview>
    </div>
  );
};
