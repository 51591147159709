import React from 'react';
import { Table, TableProps } from 'antd';
import styled from 'styled-components';

const AntdTable = styled(Table)`
  .ant-table-row-expand-icon {
    display: none;
  }

  &.script-table {
    .ant-table {
      background: var(--light-gray) !important;
    }
    .ant-table-header > table {
      border-collapse: none;
      border-spacing: 0;
    }

    .ant-table-body > table {
      border-collapse: separate;
      border-spacing: 0 10px;
    }
  }

  th {
    background-color: #fff !important;
    font-size: 14px !important;
    font-weight: 800 !important;
    color: var(--light-description) !important;
  }

  .customTableRow {
    background-color: var(--text-white) !important;
    td {
      border-spacing: 0;
    }
    td:first-of-type {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
    td:last-of-type {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
    }

    & .row-name {
      text-transform: capitalize;
    }

    &:hover > td {
      background-color: var(--light-violet) !important;
    }
  }

  @media (max-width: 900px) {
    th,
    td {
      font-size: 12px !important;
    }
    .ant-table-cell {
      padding: 8px !important;
    }
  }

  @media (max-width: 500px) {
    th,
    td {
      font-size: 10px !important;
    }
    .ant-table-cell {
      padding: 4px !important;
    }
  }
`;

export const StyledTable: React.FunctionComponent<TableProps> = ({ ...props }) => {
  return <AntdTable className="script-table" rowClassName="customTableRow" {...props} />;
};
