import React, { useState } from 'react';
import { Form, message, Space, Spin, Typography } from 'antd';
import CloseIcon from '../../../assets/close.png';
import { LoadingOutlined } from '@ant-design/icons';
import { duplicateScript } from '../../../api/apiWorker';
import { ButtonSubmit, PopUpWrapper, StyledCheckbox, StyledInput, StyledPopUpContent, StyledPopupHeader } from '../ScriptStyles';

interface IPopupDuplicateProps {
  title: string;
  resourceId: string;
  fetchScripts?: () => Promise<void>;
  setShowPopupDuplicate: React.Dispatch<React.SetStateAction<boolean | null>>;
}

interface DuplicateForm {
  title: string;
  keepSharing: boolean;
}

const PopupDuplicateScript: React.FC<IPopupDuplicateProps> = ({ setShowPopupDuplicate, fetchScripts, title, resourceId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableButtonsSubmit, setIsDisableButtonSubmit] = useState(false);
  const [form] = Form.useForm<DuplicateForm>();

  const handleSubmit = async (value: DuplicateForm) => {
    setIsLoading(true);
    setIsDisableButtonSubmit(true);
    try {
      const duplicateScriptData = {
        copy_sharing_options: value.keepSharing,
        new_resource_name: value.title,
        resource_id: resourceId
      };
      if (!resourceId) return;
      await duplicateScript(duplicateScriptData);
      fetchScripts && fetchScripts();
      setShowPopupDuplicate(false);
    } catch (e) {
      message.error('Failed duplicate of script');
    } finally {
      setIsLoading(false);
      setIsDisableButtonSubmit(false);
    }
  };

  const handleValueChange = () => {
    const values = form.getFieldsValue();
    if (!values.title) {
      setIsDisableButtonSubmit(true);
    } else {
      setIsDisableButtonSubmit(false);
    }
  };

  return (
    <PopUpWrapper>
      <StyledPopUpContent>
        <StyledPopupHeader>
          <Typography.Text className="text-title fnt-open-sans" style={{ fontSize: '24px', fontWeight: '700' }}>
            Create Duplicate
          </Typography.Text>

          <div className="close" onClick={() => setShowPopupDuplicate(null)}>
            <img src={CloseIcon} alt={''} />
          </div>
        </StyledPopupHeader>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          disabled={isLoading}
          initialValues={{ title: `${title} (copy)` || '', keepSharing: true }}
          style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
          onValuesChange={handleValueChange}
        >
          <Form.Item style={{ width: '100%', marginBottom: '0', textAlign: 'left' }} name="title">
            <StyledInput type="text" size="large" className="fnt-mulish primary-text" placeholder="Enter new script name" />
          </Form.Item>
          <Form.Item style={{ width: '100%', marginBottom: '0', textAlign: 'left' }} name="keepSharing" valuePropName="checked">
            <StyledCheckbox defaultChecked={true}>
              <Typography className="regular-16 light-description fnt-mulish">Keep parent sharing settings</Typography>
            </StyledCheckbox>
          </Form.Item>

          <ButtonSubmit type="submit" disabled={isDisableButtonsSubmit || isLoading}>
            <Space>
              {' '}
              Duplicate Script
              <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="small" />
            </Space>
          </ButtonSubmit>
        </Form>
      </StyledPopUpContent>
    </PopUpWrapper>
  );
};

export default PopupDuplicateScript;
