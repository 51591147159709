import React, { useState } from 'react';
import { Account } from '../../types';
import { Space, Tag, Typography } from 'antd';
import styled from 'styled-components';
import { RegistrationFormLabel } from '../../components/Antd/FormLabel';
import { BackToMainBtn, StyledButton } from '../../assets/styles/Styled';
import { CheckIcon, CopyIcon } from '../../assets/icons';
import { Link } from 'react-router-dom';

interface Props extends Account {}

const ItemContainer = styled.div`
  height: 52px;
  width: 100%;
  padding: 0 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07);
`;

const SpaceSticky = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: sticky;
  bottom: 0px;
`;

const VerticalAlign = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Information: React.FunctionComponent<Props> = ({ email, password, role, organizations, first_name, last_name }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const roleTitle = role === 'mapander' ? 'MaPander' : 'Admin';

  const organizationsList = organizations?.length ? organizations?.map(org => org.organization_name).join(', ') : 'None';

  const copyToClipboard = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      if (email && role && password) {
        const infoToCopy = `First Name: ${first_name || ''}\nSecond Name: ${last_name || ''}\nEmail: ${email}\nRole: ${role} \nPassword: ${password} \n Organizations: ${organizationsList}`;

        await navigator.clipboard.writeText(infoToCopy);
        setIsCopied(true);
      }
    } catch (err) {
      console.error('Could not copy text: ', err);
    }

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <VerticalAlign className="w-100">
      <div>
        <RegistrationFormLabel text="First Name" />
        <ItemContainer>
          <Typography className="semibold-16 primary-text">{first_name}</Typography>
        </ItemContainer>
      </div>
      <div>
        <RegistrationFormLabel text="Second Name" />
        <ItemContainer>
          <Typography className="semibold-16 primary-text">{last_name}</Typography>
        </ItemContainer>
      </div>
      <div>
        <RegistrationFormLabel text="Email" />
        <ItemContainer>
          <Typography className="semibold-16 primary-text">{email}</Typography>
        </ItemContainer>
      </div>
      <div>
        <RegistrationFormLabel text="Password" />
        <ItemContainer>
          <Typography className="semibold-16 primary-text">{password}</Typography>
        </ItemContainer>
      </div>
      <div>
        <RegistrationFormLabel text="Role" />
        <ItemContainer>
          <Typography className="semibold-16 primary-text">{roleTitle}</Typography>
        </ItemContainer>
      </div>
      <div>
        <RegistrationFormLabel text="Organizations" />
        <ItemContainer>
          <Typography.Text ellipsis={{ tooltip: organizationsList }} className="semibold-16 primary-text">
            {organizationsList}
          </Typography.Text>
        </ItemContainer>
      </div>
      <SpaceSticky className="w-100">
        {isCopied ? (
          <StyledButton className="btn-green" onClick={copyToClipboard}>
            <Space size={16}>
              <CheckIcon className="text-white" />
              Information copied
            </Space>
          </StyledButton>
        ) : (
          <StyledButton className="btn-orange" onClick={copyToClipboard}>
            <Space size={16}>
              <CopyIcon className="text-white" />
              Copy information
            </Space>
          </StyledButton>
        )}
        <Link to="/admin" className="mb-3">
          <BackToMainBtn>Back to Main Screen</BackToMainBtn>
        </Link>
      </SpaceSticky>
    </VerticalAlign>
  );
};
