import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import styled, { css } from 'styled-components';

interface IModalSharingWarning {
  setIsVisible: (param: boolean) => void;
  isConfirmForm: boolean;
}

const buttonBaseStyles = css`
  width: 143px;
  height: 48px;
  padding: 2px 0;
  border-radius: 97px;
  font-weight: 700;
  font-size: 13px;
`;

const StyledOkButton = styled(Button)`
  ${buttonBaseStyles};
  color: #fff;
  background: linear-gradient(180deg, #ffd08a 0%, #ed6400 100%);
  border: none;
  &:hover {
    color: #fff !important;
    background: linear-gradient(180deg, #ffd08a 0%, #ed6400 100%) !important;
    border: none !important;
  }
`;

const StyledCancelButton = styled(Button)`
  ${buttonBaseStyles};
  border: 1px solid #ff8832;
  color: #ff8832;
  background: #fff;
  &:hover {
    border: 1px solid #ff8832 !important;
    color: #ff8832 !important;
    background: #fff !important;
  }
`;

const CenteredButtons = styled.div`
  display: flex;
  justify-content: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const useModalSharingWarning = (): IModalSharingWarning => {
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmForm, setIsConfirmForm] = useState(false);

  const { Title, Text } = Typography;
  const titleStyles: CSSProperties = {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '32.68px',
    textAlign: 'center',
    color: '#0B3D4C'
  };

  const bodyStyles: CSSProperties = {
    fontFamily: "'Mulish', sans-serif '",
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '20.08px',
    textAlign: 'center',
    color: '#0B3D4C'
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleDiscard = async () => {
    setIsConfirmForm(true);
    setIsVisible(false);
  };
  useEffect(() => {
    if (isVisible) {
      Modal.confirm({
        title: <Title style={titleStyles}>Discard Unsaved Changes?</Title>,
        content: (
          <Text style={bodyStyles}>
            Looks like sharing options for the script have unsaved information. Empty the fields of sharing options or save them
            by clicking on “Add to Sharing List”
          </Text>
        ),

        icon: null,
        closable: false,
        width: 350,
        className: 'modal-sharing-confirm',

        footer: (
          <CenteredButtons>
            <StyledCancelButton onClick={handleCancel}>Cancel</StyledCancelButton>
            <StyledOkButton onClick={handleDiscard}>Discard</StyledOkButton>
          </CenteredButtons>
        )
      });
    } else {
      Modal.destroyAll();
    }

    return () => {
      Modal.destroyAll();
    };
  }, [isVisible]);

  return {
    setIsVisible,
    isConfirmForm
  };
};

export default useModalSharingWarning;
