import React, { useCallback } from 'react';
import { Dropdown, message, Modal, Typography } from 'antd';
import { EditOutlined, DeleteOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { changePassword, changeStatus, deleteAccount } from '../../api/apiWorker';
import { Account } from '../../types';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { DropdownIcon } from '../../assets/icons';

interface DropDownProps {
  account: Account;
  setAccountTo: (value: Account) => void;
  getAllAccounts: () => void;
}

export const DropDown: React.FC<DropDownProps> = ({ account, setAccountTo, getAllAccounts }) => {
  const navigate = useNavigate();
  const { setGlobalLoading, defaultLoadingState } = useAppContext();

  const handleDelete = useCallback(async () => {
    const { user_id, status } = account;
    if (!user_id || !status) return null;

    setGlobalLoading({ spin: true, tip: 'Deleting account' });
    try {
      await deleteAccount(user_id, status);
      getAllAccounts();
    } catch (error) {
      message.error('Error deleting account');
      console.error('error', error);
    } finally {
      setGlobalLoading(defaultLoadingState);
    }
  }, [account, getAllAccounts, setGlobalLoading]);

  const onRemove = useCallback(() => {
    Modal.confirm({
      title: 'Are you sure?',
      centered: true,
      content: (
        <Typography className="regular-14">
          Please, confirm deleting <b>{account.email}</b> account
        </Typography>
      ),
      closable: true,
      okText: 'Delete',
      okButtonProps: { danger: true },
      onOk: () => handleDelete()
    });
  }, [account, handleDelete]);

  const handleRegeneratePassword = async () => {
    setGlobalLoading({ spin: true, tip: 'Generating Password' });
    try {
      const res = await changePassword(account.user_id);
      if (res?.password) {
        const accountInfo = { ...account, password: res.password };

        navigate('/user-registration', { state: accountInfo });
        message.success('The password has been successfully regenerated');
      }
    } catch (error) {
      message.error('Failed to regenerate password.');
      console.error('error', error);
    } finally {
      setGlobalLoading(defaultLoadingState);
    }
  };

  const handleEditAccount = () => {
    setAccountTo(account);
  };

  const handleChangeAccountStatus = async (newStatus: string) => {
    setGlobalLoading({ spin: true, tip: 'Changing account status' });
    try {
      await changeStatus(account.user_id, newStatus);
      getAllAccounts();
      message.success(`Account status changed to ${newStatus}`);
    } catch (error) {
      message.error('Error changing account status');
      console.error('error', error);
    } finally {
      setGlobalLoading(defaultLoadingState);
    }
  };
  const menuItems = [
    {
      label: <Typography className="normal-16 text-secondary">Edit account</Typography>,
      key: 'edit',
      icon: <EditOutlined />,
      onClick: handleEditAccount
    },
    {
      label: <Typography className="normal-16 text-secondary">Regenerate password</Typography>,
      key: 'regenerate',
      icon: <LockOutlined />,
      onClick: handleRegeneratePassword,
      disabled: account.providers ? account.providers.some(provider => provider.provider !== 'mapanda') : false
    },
    {
      label: (
        <Typography className="normal-16 text-secondary">
          {account.status === 'active' ? 'Deactivate account' : 'Activate account'}
        </Typography>
      ),
      key: 'changeStatus',
      icon: account.status === 'active' ? <UnlockOutlined /> : <LockOutlined />,
      onClick: () => handleChangeAccountStatus(account.status === 'active' ? 'deactivated' : 'active')
    },
    {
      label: <Typography className="normal-16 text-red">Delete account</Typography>,
      key: 'delete',
      icon: <DeleteOutlined className="text-red" />,
      onClick: onRemove
    }
  ];

  return (
    <Dropdown menu={{ items: menuItems }} trigger={['click']}>
      <DropdownIcon className="hint-color cursor-pointer" />
    </Dropdown>
  );
};
