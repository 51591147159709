import React, { useState } from 'react';
import { StyledTable } from '../../components/Antd/StyledTable';
import { Account, OrganizationItem } from '../../types';
import { calculateTimeAgo } from '../../helper';
import { TableProps } from 'antd';
import { DropDown } from './DropDown';
import PopUpEdit from '../../components/PopUPEdit';
import { OrganizationTags } from '../../components/OrganizationTags';
import { useAuth } from '../../context/AuthContext';

interface Props {
  tableData: Account[];
  loading: boolean;
  getAllAccounts: () => void;
}

export const AccountsTable: React.FunctionComponent<Props> = ({ tableData, loading, getAllAccounts }) => {
  const [ShowPopUpEdit, setShowPopUpEdit] = useState<{ account: Account | null }>({
    account: null
  });
  const { user } = useAuth();
  const isAdmin = user?.user.role === 'admin';

  const tableColumns: TableProps<Account>['columns'] = [
    {
      title: 'Name',
      dataIndex: ['first_name', 'last_name'],
      key: 'name',
      className: 'extrabold-14',
      width: '45%',
      ellipsis: true,
      render: (text, record) => (
        <span className="row-name">
          {record.first_name || ''} {record?.last_name || ''}
        </span>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: 'extrabold-14',
      width: '45%',
      ellipsis: true
    },
    {
      title: 'Organization',
      dataIndex: 'organizations',
      key: 'organizations',
      width: '25%',
      render: (organizations: OrganizationItem[]) => (
        <OrganizationTags organizations={organizations.map(org => org.organization_name)} />
      )
    },
    {
      title: 'Time on system',
      dataIndex: 'created_at',
      key: 'created_at',
      className: 'extrabold-14',
      width: '20%',
      render: date => calculateTimeAgo(date)
    },
    {
      title: ' ',
      key: 'menu',
      width: '8%',
      align: 'center',
      render: (value, account) => {
        if (isAdmin) {
          return (
            <DropDown account={account} setAccountTo={account => setShowPopUpEdit({ account })} getAllAccounts={getAllAccounts} />
          );
        }
        return null;
      }
    }
  ];

  return (
    <div>
      {ShowPopUpEdit.account && (
        <PopUpEdit
          accountTo={ShowPopUpEdit.account}
          getAllAccounts={getAllAccounts}
          setShowPopUpEdit={() => setShowPopUpEdit({ account: null })}
        />
      )}
      <StyledTable
        columns={tableColumns}
        dataSource={tableData}
        rowKey="user_id"
        loading={loading}
        pagination={false}
        scroll={{ y: 'calc(100vh - 390px)', x: 300 }}
        showSorterTooltip={false}
      />
    </div>
  );
};
