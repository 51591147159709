import React from 'react';
import styled from 'styled-components';
import { FileIcon } from '../../../../assets/icons';
import { Spin, Typography } from 'antd';
import { convertBytes } from '../../../../helper';

const Container = styled.div`
  width: 100%;
  padding: 10px;
  display: grid;
  gap: 15px;
  grid-template-columns: 30px auto min-content;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0.876px 3.505px 0 rgba(0, 0, 0, 0.07);
`;

interface Props {
  completed: boolean;
  file: File & { uid: string };
  onRemove: (uid: string) => void;
}

export const SelectedFile: React.FunctionComponent<Props> = ({ file, onRemove, completed }) => {
  return (
    <Spin spinning={!completed}>
      <Container>
        <FileIcon className="violet-solid" />
        <div>
          <Typography className="bold-14 primary-color">{file.name}</Typography>
          <Typography className="bold-1 violet-solid">{convertBytes(file.size)}</Typography>
        </div>
      </Container>
    </Spin>
  );
};
