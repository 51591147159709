import React from 'react';
import { StyledTable } from '../../components/Antd/StyledTable';
import { OrganizationItem, Resource } from '../../types';
import { TableProps } from 'antd';
import { DropDown } from './DropDown';
import { OrganizationTags } from '../../components/OrganizationTags';
import { FileIcon, FolderIcon } from '../../assets/icons';
import { IRowSelection } from './popups/PopupSelectScriptOrFolder';

interface Props {
  tableData: Resource[];
  loading: boolean;
  rowSelection?: IRowSelection;
  onRemove?: (scriptId: string) => void;
  fetchScripts?: () => Promise<void>;
  tableColumnsProps?: TableProps<Resource>['columns'];
  handleRowDoubleClick?: (record: Resource) => void;
  isHideBody?: boolean;
}

export const ScriptsTable: React.FunctionComponent<Props> = ({
  tableData,
  loading,
  onRemove,
  fetchScripts,
  handleRowDoubleClick,
  tableColumnsProps,
  rowSelection,
  isHideBody
}) => {
  const tableColumns: TableProps<Resource>['columns'] = [
    {
      title: 'Type',
      dataIndex: 'resource_type',
      key: 'resource_type',
      className: 'extrabold-14',
      width: '8%',
      render: (resource_type: Resource['resource_type']) =>
        resource_type === 'script' ? (
          <FileIcon style={{ width: '18px', height: '22px', verticalAlign: 'inherit' }} className="hint-color" />
        ) : (
          <FolderIcon style={{ width: '18px', height: '22px', verticalAlign: 'inherit' }} />
        )
    },
    {
      title: 'Name',
      dataIndex: 'resource_name',
      key: 'resource_name',
      className: 'extrabold-14',
      width: '25%',
      ellipsis: true
    },
    {
      title: 'Creator',
      dataIndex: 'owner_id',
      key: 'owner',
      className: 'extrabold-14',
      width: '25%',
      ellipsis: true
      // render: () => <span>someCreator@gmail.com</span>
    },
    {
      title: 'Organization',
      dataIndex: 'organization_names',
      key: 'organization_names',
      width: '32%',
      render: (organizations: string[]) => <OrganizationTags organizations={organizations.map(item => item)} />
    },
    {
      title: ' ',
      key: 'menu',
      width: '8%',
      align: 'center',
      render: (value, resourse) => <DropDown resourse={resourse} onRemove={onRemove} fetchScripts={fetchScripts} />
    }
  ];

  return (
    <StyledTable
      rowSelection={rowSelection}
      columns={tableColumnsProps || tableColumns}
      dataSource={tableData}
      rowKey="resource_id"
      loading={loading}
      pagination={false}
      scroll={{ y: isHideBody ? 0 : 'calc(100vh - 390px)', x: 300 }}
      showSorterTooltip={false}
      onRow={record => ({
        onDoubleClick: () => handleRowDoubleClick && handleRowDoubleClick(record)
      })}
    />
  );
};
