import moment from 'moment';
import _ from 'lodash';
import { Account, FileType, IUploadData, Resource, SelectFormItem } from './types';

export const calculateTimeAgo = (dateString: string) => {
  if (!dateString) return null;

  const date = moment(dateString);
  const now = moment();

  const diffDays = now.diff(date, 'days');
  const diffHours = now.diff(date, 'hours');

  if (diffDays > 365) {
    const years = Math.floor(diffDays / 365);
    return `${years} year${years > 1 ? 's' : ''}`;
  } else if (diffDays > 30) {
    const months = Math.floor(diffDays / 30);
    return `${months} month${months > 1 ? 's' : ''}`;
  } else if (diffDays >= 1) {
    return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
  } else {
    return `${diffHours} hour${diffHours > 1 ? 's' : ''}`;
  }
};

export const sortByDate = (a: string, b: string) => {
  const dateA = new Date(a || '1970').getTime();
  const dateB = new Date(b || '1970').getTime();

  return dateB - dateA;
};

export const sortByText = (a: string, b: string) => {
  const fieldA = a == null ? '' : a;
  const fieldB = b == null ? '' : b;

  return fieldA.localeCompare(fieldB);
};

export const sortByNumber = (a: number, b: number) => {
  const fieldA = a == null ? 0 : a;
  const fieldB = b == null ? 0 : b;

  return fieldB - fieldA;
};

export const areArraysEqual = (array1: string[] | undefined, array2: string[] | undefined) => {
  if (array1 === undefined && array2 === undefined) {
    return true;
  }

  if (array1 === undefined || array2 === undefined) {
    return false;
  }

  return _.isEqual(_.sortBy(array1), _.sortBy(array2));
};

export const getMultValueFromEvent = (values: SelectFormItem[]) => {
  return values.map(value => ({ label: value?.label, value: value?.value }));
};

export const extractOrganizationsFromScripts = (scripts: Resource[]) => {
  const organizationMap: string[] = [];

  scripts.forEach(script => {
    script.organization_names?.forEach(org => {
      //  organizationMap.set(org.organization_id, org);
      organizationMap.push(org);
    });
  });

  return [...new Set(organizationMap)];
};

export const extractOrganizationsFromAccounts = (accounts: Account[]) => {
  // const organizationMap = new Map();
  const organizationMap: string[] = [];

  accounts.forEach(account => {
    account.organizations?.forEach(acc => {
      // organizationMap.set(acc.organization_id, acc);
      organizationMap.push(acc.organization_name);
    });
  });
  return [...new Set(organizationMap)];
  // return Array.from(organizationMap.values());
};

export const convertBytes = (sizeInBytes: number) => {
  const ONE_KB = 1024;
  const ONE_MB = 1024 * ONE_KB;

  if (sizeInBytes < ONE_MB) {
    return `${(sizeInBytes / ONE_KB).toFixed(0)} KB`;
  } else {
    return `${(sizeInBytes / ONE_MB).toFixed(1)} MB`;
  }
};

export const convertFormDataForS3 = (fields: IUploadData['fields'], file: FileType): FormData => {
  const formData = new FormData();
  formData.append('key', fields.key);
  formData.append('policy', fields.policy);
  formData.append('x-amz-algorithm', fields['x-amz-algorithm']);
  formData.append('x-amz-credential', fields['x-amz-credential']);
  formData.append('x-amz-date', fields['x-amz-date']);
  formData.append('x-amz-security-token', fields['x-amz-security-token']);
  formData.append('x-amz-signature', fields['x-amz-signature']);
  formData.append('Content-Type', 'application/pdf'); // only PDF
  formData.append('file', file);
  return formData;
};
