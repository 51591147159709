import React from 'react';
import { ScriptFormProvider } from './ScriptCreateStore';
import { ScriptForm } from './ScriptForm';
import { PreviewProvider } from '../../context/PreviewContext';

const NewScriptsPage = () => {
  return (
    <PreviewProvider>
      <ScriptFormProvider>
        <ScriptForm />
      </ScriptFormProvider>
    </PreviewProvider>
  );
};

export default NewScriptsPage;
