import { useCallback, useState } from 'react';
import { getCurrentVersion, updateCurrentVersion } from '../api/apiWorker';
import { message } from 'antd';

export const useGetCurrentVersion = () => {
  const [loading, setLoading] = useState(false);
  const [currentVersion, setCurrentVersion] = useState('');

  const fetchCurrentVersion = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getCurrentVersion();
      setCurrentVersion(result);
    } catch (e) {
      message.error('Unable to fetch version');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const updateVersion = useCallback(async (newVersion: string) => {
    setLoading(true);
    try {
      await updateCurrentVersion(newVersion);
      setCurrentVersion(newVersion);
      message.success('Version was successfully updated');
    } catch (error) {
      message.error('Unable to update version');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return { fetchCurrentVersion, updateVersion, currentVersion, loading };
};
