import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { IPreviewConfig, LoItem, LoItemToPreview, PaintPreview, WhiteboardPreview, WhiteboardPreviewItems } from '../types';

interface PreviewContextType {
  editableBlockId: string | null;
  activeBlockId: string | null;
  previewConfig: IPreviewConfig;
  whiteboardPreview: WhiteboardPreview;
  whiteboardIconIdx: number;
  whiteboardPreviewWithOrder: WhiteboardPreviewItems[];
  whiteboardEditableBlock: WhiteboardPreview;
  loPreview: LoItem[];
  loToPreview: LoItemToPreview;
  loInitialFormValues: LoItemToPreview;
  lastSavedCharacterPosition: string;
  paintToPreview: PaintPreview;
  activeLoToPreview: string[];
  setEditableBlockId: React.Dispatch<React.SetStateAction<null | string>>;
  setActiveBlockId: React.Dispatch<React.SetStateAction<null | string>>;
  setPreviewConfig: React.Dispatch<React.SetStateAction<IPreviewConfig>>;
  setWhiteboardPreview: React.Dispatch<React.SetStateAction<WhiteboardPreview>>;
  setWhiteboardEditableBlock: React.Dispatch<React.SetStateAction<WhiteboardPreview>>;
  setWhiteboardPreviewWithOrder: React.Dispatch<React.SetStateAction<WhiteboardPreviewItems[]>>;
  setWhiteboardIconIdx: React.Dispatch<React.SetStateAction<number>>;
  setLoPreview: React.Dispatch<React.SetStateAction<LoItem[]>>;
  setLoToPreview: React.Dispatch<React.SetStateAction<LoItemToPreview>>;
  setLoInitialFormValues: React.Dispatch<React.SetStateAction<LoItemToPreview>>;
  setActiveLoToPreview: React.Dispatch<React.SetStateAction<string[]>>;
  setLastSavedCharacterPosition: React.Dispatch<React.SetStateAction<string>>;
  setPaintToPreview: React.Dispatch<React.SetStateAction<PaintPreview>>;
}

const PreviewContext = createContext<PreviewContextType | undefined>(undefined);

export const PreviewProvider = ({ children }: { children: ReactNode }) => {
  const [editableBlockId, setEditableBlockId] = useState<string | null>(null);
  const [activeBlockId, setActiveBlockId] = useState<string | null>(null);

  const [previewConfig, setPreviewConfig] = useState<IPreviewConfig>({});
  const [whiteboardPreview, setWhiteboardPreview] = useState<WhiteboardPreview>({});
  const [whiteboardEditableBlock, setWhiteboardEditableBlock] = useState<WhiteboardPreview>({});
  const [whiteboardPreviewWithOrder, setWhiteboardPreviewWithOrder] = useState<WhiteboardPreviewItems[]>([]);
  const [whiteboardIconIdx, setWhiteboardIconIdx] = useState(-1);
  const [lastSavedCharacterPosition, setLastSavedCharacterPosition] = useState<string>('');

  const [loPreview, setLoPreview] = useState<LoItem[]>([]);
  const [loToPreview, setLoToPreview] = useState<LoItemToPreview>({});
  const [loInitialFormValues, setLoInitialFormValues] = useState<LoItemToPreview>({});

  const [paintToPreview, setPaintToPreview] = useState<PaintPreview>({});
  const [activeLoToPreview, setActiveLoToPreview] = useState<string[]>([]);

  useEffect(() => {
    if (Object.keys(loToPreview).length && activeBlockId) {
      const activeLoUniqIds = loToPreview[activeBlockId] ? loToPreview[activeBlockId]?.map(lo => lo.uniq_id) : [];
      setActiveLoToPreview(activeLoUniqIds || []);
    } else {
      setActiveLoToPreview([]);
    }
  }, [activeBlockId, loToPreview]);

  return (
    <PreviewContext.Provider
      value={{
        editableBlockId,
        previewConfig,
        whiteboardPreview,
        whiteboardIconIdx,
        whiteboardPreviewWithOrder,
        whiteboardEditableBlock,
        loPreview,
        loToPreview,
        loInitialFormValues,
        lastSavedCharacterPosition,
        paintToPreview,
        activeBlockId,
        activeLoToPreview,
        setActiveLoToPreview,
        setActiveBlockId,
        setLastSavedCharacterPosition,
        setLoPreview,
        setEditableBlockId,
        setPreviewConfig,
        setWhiteboardPreview,
        setWhiteboardPreviewWithOrder,
        setWhiteboardIconIdx,
        setWhiteboardEditableBlock,
        setLoToPreview,
        setLoInitialFormValues,
        setPaintToPreview
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
};

export const usePreview = () => {
  const context = useContext(PreviewContext);
  if (!context) {
    throw new Error('usePreview must be used within a PreviewProvider');
  }
  return context;
};
