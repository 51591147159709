import React, { useCallback, useState } from 'react';
import { Dropdown, MenuProps, Modal, Tooltip, Typography } from 'antd';
import { DropdownIcon } from '../../assets/icons';
import { Resource } from '../../types';
import { useLocation, useNavigate } from 'react-router-dom';
import PopupDuplicateScript from './popups/PopupDuplicateScript';
import PopupDeliteFolder from './popups/PopupDeliteFolder';
import { PopupSelectScriptOrFolder } from './popups/PopupSelectScriptOrFolder';
import { PopupSharedFolder } from './popups/PopupSharedFolder';
import { PopupEditFolder } from './popups/PopupEditFolder';

interface Props {
  resourse: Resource;
  onRemove?: (scriptId: string) => void;
  fetchScripts?: () => Promise<void>;
  menuItemsProps?: MenuProps['items'];
}

export const DropDown: React.FunctionComponent<Props> = ({ resourse, menuItemsProps, onRemove, fetchScripts }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showPopupDuplicate, setShowPopupDuplicate] = useState<boolean | null>(null);
  const [showPopupDeleteFolder, setShowPopupDeleteFolder] = useState<boolean | null>(null);
  const [showPopupSelectScript, setShowPopupSelectScript] = useState<boolean | null>(null);
  const [showPopupSharedFolder, setShowPopupSharedFolder] = useState<boolean | null>(null);
  const [showPopupEditFolder, setShowPopupEditFolder] = useState<boolean | null>(null);

  const [selectedResourceType, setSelectedResourceType] = useState<'folder' | 'script'>('script');
  const params = new URLSearchParams(location.search);
  const folderId = params.get('folderId');

  const menuItems: MenuProps['items'] =
    resourse.resource_type !== 'folder'
      ? [
          {
            label: <Typography className="normal-16 text-secondary fnt-mulish">Edit script</Typography>,
            key: 'edit',
            // icon: <EditOutlined className="normal-20 text-secondary" />,
            onClick: () =>
              navigate(folderId ? `/scripts/${resourse.resource_id}?parentId=${folderId}` : `/scripts/${resourse.resource_id}`, {
                state: resourse
              })
          },
          {
            label: resourse.copy_allowed ? (
              <Typography className="normal-16  text-secondary fnt-mulish">Duplicate</Typography>
            ) : (
              <Tooltip
                title="You are not allowed to duplicate this script. Contact your administratorto help"
                overlayClassName="custom-tooltip"
                placement="bottom"
              >
                <Typography className="normal-16  text-disabled fnt-mulish">Duplicate</Typography>
              </Tooltip>
            ),
            key: 'duplicate',
            disabled: false,
            onClick: () => handleDuplicate()
          },
          {
            label: <Typography className="normal-16 text-secondary fnt-mulish">Move to folder</Typography>,
            key: 'moveToFolder',
            onClick: () => {
              setShowPopupSelectScript(true);
              setSelectedResourceType('folder');
            }
          },
          // {
          //   label: <Typography className="normal-16 text-secondary fnt-mulish">Share to Organizations</Typography>,
          //   key: 'shareToOrganizations',
          //   onClick: () => setShowPopupSharedFolder && setShowPopupSharedFolder(true)
          // },
          {
            label: <Typography className="normal-16 text-red fnt-mulish">Delete script</Typography>,
            key: 'delete',
            // icon: <DeleteOutlined className="normal-20 text-red" />,
            onClick: () => handleRemove()
          }
        ]
      : [
          {
            label: <Typography className="normal-16 text-secondary fnt-mulish">Edit Folder</Typography>,
            key: 'edit',
            // icon: <EditOutlined className="normal-20 text-secondary" />,
            onClick: () => {
              setShowPopupEditFolder(true);
              // navigate(`/scripts/${script.resource_id}`, { state: script })
            }
          },
          {
            label: <Typography className="normal-16 text-secondary fnt-mulish">Sharing Options</Typography>,
            key: 'shareToOrganizations',
            onClick: () => setShowPopupSharedFolder && setShowPopupSharedFolder(true)
          },
          {
            label: <Typography className="normal-16 text-secondary fnt-mulish">Add Existing Scripts</Typography>,
            key: 'addExisting',
            onClick: () => {
              setShowPopupSelectScript(true);
              setSelectedResourceType('script');
            }
          },
          {
            label: <Typography className="normal-16 text-red fnt-mulish">Delete folder</Typography>,
            key: 'delete',
            // icon: <DeleteOutlined className="normal-20 text-red" />,
            onClick: () => {
              if (resourse.resource_type === 'folder') {
                setShowPopupDeleteFolder(true);
              } else {
                handleRemove();
              }
            }
          }
        ];

  const handleRemove = useCallback(() => {
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <Typography className="regular-14">
          Please, confirm deleting <b>{resourse.resource_name}</b> {resourse.resource_type}
        </Typography>
      ),
      okText: 'Delete',
      closable: true,
      okButtonProps: { danger: true },
      onOk: () => {
        if (resourse.resource_id && onRemove) {
          onRemove(resourse.resource_id);
        }
      }
    });
  }, [resourse, onRemove, showPopupDeleteFolder]);

  const handleDuplicate = () => {
    if (!resourse.copy_allowed) return;
    setShowPopupDuplicate(true);
  };

  return (
    <>
      {showPopupDuplicate && (
        <PopupDuplicateScript
          setShowPopupDuplicate={setShowPopupDuplicate}
          fetchScripts={fetchScripts}
          title={resourse.resource_name}
          resourceId={resourse.resource_id}
        />
      )}
      {showPopupDeleteFolder && (
        <PopupDeliteFolder folder={resourse} onRemove={onRemove} setShowPopupDeleteFolder={setShowPopupDeleteFolder} />
      )}

      {showPopupSelectScript && (
        <PopupSelectScriptOrFolder
          setShowPopupSelectScript={setShowPopupSelectScript}
          resourceType={selectedResourceType}
          onRemove={handleRemove}
        />
      )}
      {showPopupSharedFolder && (
        <PopupSharedFolder
          setShowPopupSharedFolder={setShowPopupSharedFolder}
          resourceId={resourse.resource_id}
          fetchScripts={fetchScripts}
        />
      )}
      {showPopupEditFolder && (
        <PopupEditFolder
          setShowPopupEditFolder={setShowPopupEditFolder}
          resourceId={resourse.resource_id}
          resourceName={resourse.resource_name}
          fetchScripts={fetchScripts}
        />
      )}
      <Dropdown className="drop-munu" trigger={['click']} menu={{ items: menuItemsProps?.length ? menuItemsProps : menuItems }}>
        <DropdownIcon className="hint-color cursor-pointer" />
      </Dropdown>
    </>
  );
};
