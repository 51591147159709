import React, { useState, useRef, useEffect } from 'react';
import { uploadScript } from '../../../api/apiWorker';
import 'react-image-crop/dist/ReactCrop.css';
import { message, Modal, Spin } from 'antd';
import styled from 'styled-components';
import ReactCrop, { Crop } from 'react-image-crop';
import { StyledButton } from '../../../assets/styles/Styled';
import { RotateIcon } from '../../../assets/icons';

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  background-color: #000;
  border-radius: 17px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledIcon = styled(RotateIcon)`
  cursor: pointer;
  color: #fff;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const getImageObjectUrl = async (imageUrl: string) => {
  const response = await fetch(imageUrl, { cache: 'no-store' });
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

interface Props {
  imageUrl: string;
  onChange: (oldUrl: string, newUrl: string) => void;
  onCancel: () => void;
}

export const ImageEditorModal: React.FC<Props> = ({ imageUrl, onChange, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [cropped, setCropped] = useState(false);
  const [crop, setCrop] = useState<Crop>({ unit: '%', x: 0, y: 0, width: 100, height: 100 });
  const [rotatedImageUrl, setRotatedImageUrl] = useState('');
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (!rotatedImageUrl) {
      getImageObjectUrl(imageUrl)
        .then(url => setRotatedImageUrl(url))
        .catch(e => {
          message.error('Image converting error:', e);
        });
    }

    return () => URL.revokeObjectURL(rotatedImageUrl);
  }, [imageUrl, rotatedImageUrl]);

  const rotateImageAndUpdateDisplay = () => {
    if (!imgRef.current) return;
    const rotation = 90;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const { naturalWidth, naturalHeight } = imgRef.current;

    const radians = (rotation * Math.PI) / 180;
    const cos = Math.abs(Math.cos(radians));
    const sin = Math.abs(Math.sin(radians));
    const newWidth = naturalWidth * cos + naturalHeight * sin;
    const newHeight = naturalWidth * sin + naturalHeight * cos;

    canvas.width = newWidth;
    canvas.height = newHeight;

    ctx.translate(newWidth / 2, newHeight / 2);
    ctx.rotate(radians);
    ctx.drawImage(imgRef.current, -naturalWidth / 2, -naturalHeight / 2);

    canvas.toBlob(blob => {
      if (!blob) return;
      const rotatedImageUrl = URL.createObjectURL(blob);
      setRotatedImageUrl(rotatedImageUrl);
    }, 'image/png');
  };

  const handleSaveChanges = async () => {
    try {
      const response = await fetch(rotatedImageUrl, {
        cache: 'no-store'
      });
      const blob = await response.blob();
      const imageBitmap = await createImageBitmap(blob);

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx || !imgRef.current) return;

      if (cropped && crop.width && crop.height) {
        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.drawImage(
          imageBitmap,
          crop.x * (imageBitmap.width / imgRef.current.width),
          crop.y * (imageBitmap.height / imgRef.current.height),
          crop.width * (imageBitmap.width / imgRef.current.width),
          crop.height * (imageBitmap.height / imgRef.current.height),
          0,
          0,
          crop.width,
          crop.height
        );
      } else {
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        ctx.drawImage(imageBitmap, 0, 0);
      }

      canvas.toBlob(async blob => {
        if (!blob) {
          message.error('Error preparing the image');
          setLoading(false);
          return;
        }

        const formData = new FormData();
        formData.append('image', blob, 'edited.png');

        setLoading(true);
        const { data } = await uploadScript(formData, percent => {});
        onChange(imageUrl, data[0]);
        message.success('Image edited. Save the changes!');
      }, 'image/png');
    } catch (e) {
      message.error('Error editing image');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (!rotatedImageUrl) {
    return <Spin tip="Loading" fullscreen />;
  }

  return (
    <Modal
      open={true}
      title="Edit Image"
      width={900}
      onCancel={onCancel}
      destroyOnClose
      footer={
        <Spin spinning={loading}>
          <StyledButton className="btn-orange W-100" onClick={handleSaveChanges} disabled={loading}>
            Save Changes
          </StyledButton>
        </Spin>
      }
    >
      <Spin spinning={loading}>
        <ImageContainer>
          <ReactCrop
            style={{ maxHeight: 700, maxWidth: 900 }}
            crop={crop}
            onChange={newCrop => {
              setCropped(true);
              setCrop(newCrop);
            }}
            onComplete={newCrop => setCrop(newCrop)}
          >
            <StyledImage alt="Rotate" crossOrigin="anonymous" src={rotatedImageUrl} ref={imgRef} />
          </ReactCrop>

          <StyledIcon onClick={rotateImageAndUpdateDisplay} />
        </ImageContainer>
      </Spin>
    </Modal>
  );
};
