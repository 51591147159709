import React, { useEffect, useMemo, useState } from 'react';
import { CheckboxProps, Form, Tooltip, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  CustomCheckbox,
  CustomTag,
  ItemFieldsWrapper,
  SelectOptionsUser,
  SelectRestrictions,
  SelectRestrictionsLabel,
  SharingList,
  StyledSelect,
  StyledSharingButton,
  StyledTagRestriction
} from './SharedStyles';
import { AccountStatus, SelectFormItem } from '../../types';
import { useAuth } from '../../context/AuthContext';
import { useAppContext } from '../../context/AppContext';
import { FormLabel } from '../Antd/FormLabel';
import { getMultValueFromEvent } from '../../helper';
import { useLocation } from 'react-router-dom';
import useMedia from '../../hooks/useMedia';

interface FolderFormValues {
  users: SelectFormItem[];
  organizations: SelectFormItem[];
}

interface IFormValues {
  users: string[];
  organizations: string[];
}

type Field = 'users' | 'organizations';

export interface IFieldValue {
  label?: string | React.ReactNode;
  value: string;
  restriction?: string;
}

export interface ISharingList {
  users: IFieldValue[];
  organizations: IFieldValue[];
}

const closeIcon = <CloseOutlined className="btn-close" style={{ fontSize: '18px', marginLeft: '13px', color: '#AFCCD2' }} />;

interface IFolderSharedProps {
  allowDuplicate?: boolean;
  sharingList: ISharingList;
  setSharingList: React.Dispatch<React.SetStateAction<ISharingList>>;
  setAllowDuplicate?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SharedForm: React.FC<IFolderSharedProps> = ({ allowDuplicate, setSharingList, setAllowDuplicate, sharingList }) => {
  const { user } = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const parentId = params.get('parentId');
  const { isMobile } = useMedia();

  const { accounts, organizations, accountsLoading, organizationsLoading } = useAppContext();

  const [form] = Form.useForm<IFormValues>();

  const [formValues, setFormValues] = useState<FolderFormValues>();
  const [selectedUserValues, setSelectedUserValues] = useState<string[]>([]);
  const [selectedOrganizationsValues, setSelectedOrganizationsValues] = useState<string[]>([]);
  const [restrictionAccountValue, setRestrictionAccountValue] = useState<string>(() => (parentId ? 'edit' : 'view'));
  const [restrictionOrganizationValue, setRestrictionOrganizationValue] = useState<string>(() => (parentId ? 'edit' : 'view'));

  useEffect(() => {
    const userValues = sharingList.users.map(value => value.value);
    const organizationValues = sharingList.organizations.map(value => value.value);
    setSelectedUserValues(userValues);
    setSelectedOrganizationsValues(organizationValues);
  }, [sharingList, setSelectedUserValues, setSelectedOrganizationsValues]);

  const organizationOptions = useMemo(() => {
    return organizations.map(org => ({
      label: <SelectOptionsUser>{org.organization_name}</SelectOptionsUser>,
      value: org.organization_id,
      desc: org.organization_name
    }));
  }, [organizations]);

  const mapanderOptions = useMemo(() => {
    const options = accounts
      ?.filter(acc => acc.status === AccountStatus.Active)
      .map(acc => ({
        label: (
          <SelectOptionsUser>
            {`${acc.first_name || ''} ${acc.last_name || ''}`}
            <span className="tag-email">{acc.email}</span>
          </SelectOptionsUser>
        ),
        value: acc.user_id,
        desc: `${acc.first_name || ''} ${acc.last_name || ''} ${acc.email}`
      }));
    return options?.length
      ? options
      : user
        ? [
            {
              label: (
                <SelectOptionsUser>
                  {`${user.user.first_name || ''} ${user.user.last_name || ''}`}
                  <span className="tag-email">{user.user.email}</span>
                </SelectOptionsUser>
              ),
              value: user.user.user_id,
              desc: `${user.user.first_name || ''} ${user.user.last_name || ''} ${user.user.email}`
            }
          ]
        : [];
  }, [accounts, user]);

  const filteredUsersOptions = useMemo(() => {
    return {
      optionsSelect: mapanderOptions.filter(o => !selectedUserValues.includes(o.value)),
      optionsTag: mapanderOptions
        .filter(o => selectedUserValues.includes(o.value))
        .map(item => ({
          ...item,
          restriction: sharingList.users.find(user => user.value === item.value)?.restriction || ''
        }))
    };
  }, [selectedUserValues, mapanderOptions]);

  const filteredOrganizationOptions = useMemo(() => {
    return {
      optionsSelect: organizationOptions.filter(o => !selectedOrganizationsValues.includes(o.value)),
      optionsTag: organizationOptions
        .filter(o => selectedOrganizationsValues.includes(o.value))
        .map(item => ({
          ...item,
          restriction: sharingList.organizations.find(user => user.value === item.value)?.restriction || ''
        }))
    };
  }, [selectedOrganizationsValues, organizationOptions]);

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleAddToSharing = (e: React.MouseEvent<HTMLButtonElement>, field: Field) => {
    e.preventDefault();
    const value = form.getFieldValue(field);
    setSharingList(prev => ({
      ...prev,
      [field]: [
        ...prev[field],
        ...value.map(({ label, ...rest }: IFieldValue) => ({
          ...rest,
          restriction: field === 'users' ? restrictionAccountValue : restrictionOrganizationValue
        }))
      ]
    }));

    form.setFieldValue(field, []);
    if (field === 'users') {
      setRestrictionAccountValue(parentId ? 'edit' : 'view');
    } else if (field === 'organizations') {
      setRestrictionOrganizationValue(parentId ? 'edit' : 'view');
    }
    setFormValues(prev => ({ ...prev, [field]: [] }) as FolderFormValues);
  };

  const onClose = (id: string, field: Field) => {
    const filteredValues = sharingList[field].filter((item: IFieldValue) => item.value !== id);
    setSharingList(prev => ({
      ...prev,
      [field]: filteredValues
    }));
  };

  const handleClearAll = (e: React.MouseEvent<HTMLButtonElement>, field: Field) => {
    e.preventDefault();
    setSharingList(prev => ({
      ...prev,
      [field]: []
    }));
  };

  const onChange: CheckboxProps['onChange'] = e => {
    // setFormValues(prev => ({ ...prev, copy_allowed: e.target.checked }));
    setAllowDuplicate && setAllowDuplicate(e.target.checked);
  };

  const handleSubmit = (values: IFormValues) => {
    console.log(sharingList, 'SUBMIT FORM');
    // setShowPopupSharedFolder(null);
  };

  const blockActionForViewRestriction = (restriction: string) => {
    if (!parentId) return true;
    if (parentId && restriction === 'view') {
      return false;
    }
    return true;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={changedValues => setFormValues(prev => ({ ...prev, ...changedValues }))}
      onFinish={handleSubmit}
    >
      <ItemFieldsWrapper>
        <div style={{ width: '100%', marginBottom: '0', position: 'relative' }}>
          <Form.Item
            style={{ width: '100%', marginBottom: '0', position: 'relative' }}
            label={<FormLabel className="fnt-rounded" text="Share With Accounts" />}
            name="users"
            getValueFromEvent={getMultValueFromEvent}
          >
            <StyledSelect
              mode="multiple"
              labelInValue
              options={filteredUsersOptions.optionsSelect}
              size="large"
              optionFilterProp="desc"
              placeholder={'Account email'}
              loading={accountsLoading}
              value={sharingList}
            />
          </Form.Item>
          <SelectRestrictions
            labelInValue
            className={`select-shared-restrictions ${parentId ? 'no-arrow' : ''}`}
            options={[
              {
                label: <SelectRestrictionsLabel className="fnt-mulish">View Only</SelectRestrictionsLabel>,
                value: 'view'
              },
              {
                label: <SelectRestrictionsLabel className="fnt-mulish">Can Edit</SelectRestrictionsLabel>,
                value: 'edit'
              }
            ].filter(option => (parentId ? option.value === 'edit' : option))}
            size="small"
            value={restrictionAccountValue}
            onChange={e => setRestrictionAccountValue((e as IFieldValue).value)}
          />
        </div>

        <StyledSharingButton disabled={!form.getFieldValue('users')?.length} onClick={e => handleAddToSharing(e, 'users')}>
          Add To Sharing List
        </StyledSharingButton>
      </ItemFieldsWrapper>
      {!!filteredUsersOptions.optionsTag.length && !accountsLoading && (
        <SharingList>
          {filteredUsersOptions.optionsTag.map(item => (
            <Tooltip
              color="#ffffff"
              overlayInnerStyle={{
                minWidth: `${isMobile ? '300px' : '390px'} `,
                maxWidth: '100%',
                width: 'auto'
              }}
              overlayClassName="shared-tooltip"
              title={
                parentId &&
                item.restriction === 'view' && (
                  <Typography className="extrabold-14 hint-color text-align-center fnt-rounded">
                    This script will be avalible for viewing for all users who has access to the current folder
                  </Typography>
                )
              }
            >
              <CustomTag
                className={parentId && item.restriction === 'view' ? 'tag-disabled' : ''}
                key={item.value}
                onMouseDown={onPreventMouseDown}
                onClick={() => blockActionForViewRestriction(item.restriction) && onClose(item.value, 'users')}
              >
                {item.label}
                <StyledTagRestriction className={item.restriction === 'edit' ? 'label-green' : 'label-orange'}>
                  {`(${item.restriction === 'edit' ? 'can edit' : 'view only'})`}
                </StyledTagRestriction>
                {blockActionForViewRestriction(item.restriction) && closeIcon}
              </CustomTag>
            </Tooltip>
          ))}
          {!parentId && (
            <StyledSharingButton
              style={{ height: '40px', width: 'fit-content', minWidth: 'unset', margin: '4px 0 0' }}
              onClick={e => handleClearAll(e, 'users')}
            >
              Clear all
            </StyledSharingButton>
          )}
        </SharingList>
      )}
      <ItemFieldsWrapper className="mb-4">
        <div style={{ width: '100%', marginBottom: '0', position: 'relative' }}>
          <Form.Item
            style={{ width: '100%', marginBottom: '0' }}
            label={<FormLabel className="fnt-rounded" text="Share with Organizations" />}
            name="organizations"
            getValueFromEvent={getMultValueFromEvent}
            hidden={!organizationOptions.length}
          >
            <StyledSelect
              mode="multiple"
              labelInValue
              options={filteredOrganizationOptions.optionsSelect}
              size="large"
              showSearch
              optionFilterProp="desc"
              placeholder="Organization name"
              loading={organizationsLoading}
            />
          </Form.Item>
          {!!organizationOptions.length && (
            <SelectRestrictions
              labelInValue
              className={`select-shared-restrictions ${parentId ? 'no-arrow' : ''}`}
              options={[
                {
                  label: <SelectRestrictionsLabel className="fnt-mulish">View Only</SelectRestrictionsLabel>,
                  value: 'view'
                },
                {
                  label: <SelectRestrictionsLabel className="fnt-mulish">Can Edit</SelectRestrictionsLabel>,
                  value: 'edit'
                }
              ].filter(option => (parentId ? option.value === 'edit' : option))}
              size="small"
              value={restrictionOrganizationValue}
              onChange={e => setRestrictionOrganizationValue((e as IFieldValue).value)}
            />
          )}
        </div>
        {!!organizationOptions.length && (
          <StyledSharingButton
            disabled={!form.getFieldValue('organizations')?.length}
            onClick={e => handleAddToSharing(e, 'organizations')}
          >
            Add To Sharing List
          </StyledSharingButton>
        )}
      </ItemFieldsWrapper>
      {!!filteredOrganizationOptions.optionsTag.length && !accountsLoading && (
        <SharingList>
          {filteredOrganizationOptions.optionsTag.map(item => (
            <Tooltip
              color="#ffffff"
              overlayInnerStyle={{
                minWidth: `${isMobile ? '300px' : '390px'} `,
                maxWidth: '100%',
                width: 'auto'
              }}
              title={
                parentId &&
                item.restriction === 'view' && (
                  <Typography className="extrabold-14 hint-color text-align-center fnt-rounded">
                    This script will be avalible for viewing for all users who has access to the current folder
                  </Typography>
                )
              }
            >
              <CustomTag
                className={parentId && item.restriction === 'view' ? 'tag-disabled' : ''}
                style={{ height: '40px' }}
                key={item.value}
                onMouseDown={onPreventMouseDown}
                onClick={() => blockActionForViewRestriction(item.restriction) && onClose(item.value, 'organizations')}
              >
                {item.label}
                <StyledTagRestriction className={item.restriction === 'edit' ? 'label-green' : 'label-orange'}>
                  {`(${item.restriction === 'edit' ? 'can edit' : 'view only'})`}
                </StyledTagRestriction>
                {blockActionForViewRestriction(item.restriction) && closeIcon}
              </CustomTag>
            </Tooltip>
          ))}
          {!parentId && (
            <StyledSharingButton
              style={{ height: '40px', width: 'fit-content', minWidth: 'unset' }}
              onClick={e => handleClearAll(e, 'organizations')}
            >
              Clear all
            </StyledSharingButton>
          )}
        </SharingList>
      )}
      {setAllowDuplicate && (
        <CustomCheckbox onChange={onChange} checked={allowDuplicate}>
          <Typography className="regular-16 light-description fnt-mulish">
            Allow to duplicate script to those who have access
          </Typography>
        </CustomCheckbox>
      )}
    </Form>
  );
};
