import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ButtonOutlined, PageContent } from '../../../../assets/styles/Styled';
import { Space, Spin, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Chapter } from './components';
import { useScriptForm } from '../../ScriptCreateStore';
import { IActionsConfigPositions, Scenario } from '../../../../types';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { v4 as uuidv4 } from 'uuid';
import { NotesPreview } from './components/NotesPreview';
import { ModalSelectBackground } from './components/ModalSelectBackground';
import { useAppContext } from '../../../../context/AppContext';
import { useNotePreview } from '../../hooks/useNotePreview';
import { usePreview } from '../../../../context/PreviewContext';

const Container = styled(PageContent)`
  margin: 0 auto;
  // overflow-y: auto;
`;

const ChapterContainer = styled.div`
  position: relative;
  z-index: 0;
  height: 100%;
  width: 100%;
  padding: 16px 0 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  gap: 32px;
  border-radius: 16px;

  @media (max-width: 767px) {
    & {
      padding: 0;
      overflow-y: auto;
    }
  }
`;

const ChapterItem = styled(ChapterContainer)`
  justify-content: flex-start;
  background-color: var(--light-gray);
  padding: 16px;
  @media (max-width: 767px) {
    overflow-y: unset;
  }
`;

const ChapterWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 767px) {
    & {
      flex-direction: column-reverse;
      gap: 32px;
      overflow-y: unset;
      height: fit-content;
    }
  }
`;

const NotesBlock = styled.div`
  height: fit-content;
  width: 390px;
  background: #fff;
  position: sticky;
  padding-right: 16px;
  top: 0;
  @media (max-width: 767px) {
    & {
      width: 100%;
      position: relative;
      padding-right: 0;
      top: unset;
      padding: 0 16px;
      height: fit-content;
    }
  }
`;

const initialScenario: Scenario = {
  id: '',
  title: '',
  blocks: []
};

export const ScenarioSection = React.memo(() => {
  const {
    formValues: { scenario: scenarioState, background, slides },
    setFormValues
  } = useScriptForm();
  const { actionsConfig, baseActionPaths, liveObjectsBasePath, liveObjectsIcons } = useAppContext();

  const [expandedChapter, setExpandedChapter] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { previewConfig, setPreviewConfig, whiteboardIconIdx } = useNotePreview();
  const { loPreview, setLoToPreview, lastSavedCharacterPosition, activeLoToPreview } = usePreview();

  useEffect(() => {
    if (!background && actionsConfig.backgrounds.length) {
      return setModalVisible(true);
    }
    setModalVisible(false);
  }, [background, actionsConfig]);
  const addChapter = () => {
    const newScenario = { ...initialScenario, id: uuidv4() };
    setFormValues(prevState => ({ ...prevState, scenario: [...prevState.scenario, newScenario] }));
    setExpandedChapter(newScenario.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = scenarioState.findIndex(scenario => scenario.id === active.id);
      const newIndex = scenarioState.findIndex(scenario => scenario.id === over?.id);
      const newScenario = arrayMove(scenarioState, oldIndex, newIndex);
      setFormValues(prevState => ({ ...prevState, scenario: newScenario }));
    }
  };

  const slideIndex = useMemo(() => {
    return whiteboardIconIdx as number;
  }, [whiteboardIconIdx]);
  const iconPreview = useMemo(() => {
    if (!previewConfig['backgrounds'] && !background) {
      const backgroundDay = actionsConfig.backgrounds.filter(item => item.id === 'Day');
      return backgroundDay[0]?.id;
    } else if (!previewConfig['backgrounds'] && background) {
      return background;
    }
    return actionsConfig.backgrounds.find(item => item.id === previewConfig['backgrounds'])?.id;
  }, [previewConfig, actionsConfig, background]);

  const characterPosition = useMemo(() => {
    if (
      slides.length &&
      slideIndex >= 0 &&
      previewConfig['character_moving_actions'] !== 'PositionLeft' &&
      previewConfig['character_moving_actions'] !== 'PositionRight'
    ) {
      return actionsConfig.character_moving_actions.find(item => item.id === 'PositionLeft')?.icon;
    } else if (previewConfig['character_moving_actions'] && slides.length && slideIndex === -1 && lastSavedCharacterPosition) {
      return actionsConfig.character_moving_actions.find(item => item.id === lastSavedCharacterPosition)
        ?.icon as IActionsConfigPositions['icon'];
    } else {
      if (previewConfig['character_moving_actions']) {
        return actionsConfig.character_moving_actions.find(item => item.id === previewConfig['character_moving_actions'])
          ?.icon as IActionsConfigPositions['icon'];
      } else {
        return actionsConfig.character_moving_actions[0]?.icon;
      }
    }
  }, [previewConfig, actionsConfig, slides, slideIndex, lastSavedCharacterPosition]);
  return (
    <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
      <ModalSelectBackground
        backgrounds={actionsConfig.backgrounds}
        basePath={baseActionPaths.actions_media_base_path}
        openModal={modalVisible}
        setPreviewConfig={setPreviewConfig}
      />
      <SortableContext items={scenarioState.map(scenario => scenario.id)} strategy={verticalListSortingStrategy}>
        <Container>
          <ChapterContainer>
            <ChapterWrapper>
              <ChapterItem>
                {!scenarioState.length && (
                  <Typography className="extrabold-16 hint-color text-align-center" style={{ margin: 'auto' }}>
                    Nothing added yet! <br /> Let`s add first chapter by “Add Chapter” button!
                  </Typography>
                )}
                {scenarioState.map((scenario, index) => (
                  <Chapter
                    key={scenario.id}
                    scenarioIndex={index}
                    expanded={expandedChapter === scenario.id}
                    onExpand={id => {
                      // setExpandedChapter(prev => {
                      //   if (prev.includes(id)) {
                      //     return prev.filter(value => value !== id);
                      //   } else {
                      //     return [...prev, id];
                      //   }
                      // });

                      setExpandedChapter(prev => (prev === id ? null : id));
                    }}
                    scenario={scenario}
                    expandedChapter={expandedChapter}
                  />
                ))}
              </ChapterItem>
              <NotesBlock>
                {!!Object.keys(actionsConfig).length && (
                  <NotesPreview
                    basePath={baseActionPaths.actions_media_base_path}
                    background={iconPreview}
                    position={characterPosition}
                    slides={slides}
                    whiteboardSlideIdx={slideIndex >= 0 ? slideIndex : -1}
                    loPreview={loPreview}
                    liveObjectsBasePath={liveObjectsBasePath}
                    setLoToPreview={setLoToPreview}
                    isShowPencils={!!previewConfig['drawing'] && previewConfig['drawing'] === 'DrawingOn'}
                    liveObjectsIcons={liveObjectsIcons}
                    activeLoToPreview={activeLoToPreview}
                  />
                )}
                <ButtonOutlined className="btn-orange flex-shrink-0" onClick={addChapter}>
                  <Space>
                    <PlusOutlined />
                    Add Chapter
                  </Space>
                </ButtonOutlined>
              </NotesBlock>
            </ChapterWrapper>
          </ChapterContainer>
        </Container>
      </SortableContext>
    </DndContext>
  );
});
