import React from 'react';
import { SlidesPreviewBox, Uploader } from '../../components';
import { Space } from 'antd';
import { useScriptForm } from '../../ScriptCreateStore';
import styled from 'styled-components';
import { PageContent } from '../../../../assets/styles/Styled';

const Container = styled(PageContent)`
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const PresentationSection = () => {
  const { formValues, setFormValues, readOnlyMode } = useScriptForm();

  return (
    <Container>
      <Space className="w-100" direction="vertical" size={24}>
        <Uploader
          onChange={images => setFormValues(prev => ({ ...prev, slides: [...formValues.slides, ...images] }))}
          editMode={!!formValues.resource_id}
          disabled={readOnlyMode}
        />
        {!!formValues.slides.length && (
          <SlidesPreviewBox
            readonly={readOnlyMode}
            slides={formValues.slides}
            onChange={slides => setFormValues(prev => ({ ...prev, slides }))}
          />
        )}
      </Space>
    </Container>
  );
};
