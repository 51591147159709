import React from 'react';
import { Typography } from 'antd';

interface Props {
  text: string;
  className?: string;
}

export const FormLabel: React.FunctionComponent<Props> = ({ text, className }) => {
  return <Typography className={`extrabold-16 description-solid ${className ? className : ''}`}>{text}</Typography>;
};

export const RegistrationFormLabel: React.FunctionComponent<Props> = ({ text }) => {
  return <Typography className="bold-16 text-basic">{text}</Typography>;
};

export const ChangeVersionFormLabel: React.FunctionComponent<Props> = ({ text }) => {
  return <Typography className="bold-16 text-basic">{text}</Typography>;
};
