import React, { useEffect, useMemo, useState } from 'react';
import { EditBtn, PopUpContentDelete, PopUpWrapperDelete } from '../../assets/styles/Styled';
import CloseIcon from '../../assets/close.png';
import { useAppContext } from '../../context/AppContext';
import { LoadingOutlined } from '@ant-design/icons';

import {
  StyledCharacterLabel,
  ItemFieldsWrapper,
  StyledSelect,
  StyledSharingButton,
  SharingList,
  CustomTag,
  CustomCheckbox
} from './character.styled';
import { CheckboxProps, Form, Space, Spin, Typography } from 'antd';
import styled from 'styled-components';
import { FormLabel } from '../../components/Antd/FormLabel';
import { getMultValueFromEvent } from '../../helper';
import useMedia from '../../hooks/useMedia';
import { CloseOutlined } from '@ant-design/icons';
import { IChangeCharactersData, ICharacters } from '../../types';

interface IPopUpEditCharactersProps {
  setShowPopupEdit: React.Dispatch<React.SetStateAction<ICharacters | null>>;
  handleAvailableChange: ({ id, data }: { id: string; data: IChangeCharactersData }) => Promise<void>;
  character: ICharacters;
}

const StyledPopupHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: #0b3d4c;
`;

const StyledPopUpContent = styled(PopUpContentDelete)`
  max-width: 830px;
  width: 100%;
`;

const PopUpWrapper = styled(PopUpWrapperDelete)`
  z-index: 10;
`;

const ButtonSubmit = styled(EditBtn)`
  margin: 14px auto 0;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 8px;
`;

const StyledFormItemWrapper = styled.div`
  background: #f5fbfc;
  padding: 16px 8px;
  border-radius: 8px;
`;

interface CharactersForm {
  organizations: string[];
}
interface IFieldValue {
  label: JSX.Element;
  value: string;
}
interface ISharingList {
  organizations: IFieldValue[];
}

export const PopUpEditCharacters: React.FC<IPopUpEditCharactersProps> = ({
  setShowPopupEdit,
  character,
  handleAvailableChange
}) => {
  const { organizations, organizationsLoading } = useAppContext();
  const closeIcon = <CloseOutlined className="btn-close" style={{ fontSize: '18px', marginLeft: '13px', color: '#AFCCD2' }} />;
  const [isEmptyForm, setIsEmptyForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  //   const initialSharingList = {
  //     organizations: []
  //   };

  const initialSharingList = useMemo(() => {
    if (!character.organizations || !character.organizations.length) {
      return { organizations: [] };
    }
    const initSharingList = character.organizations
      .map(id => {
        const org = organizations.find(org => org.organization_id === id);
        return org
          ? {
              label: <StyledCharacterLabel>{org.organization_name}</StyledCharacterLabel>,
              value: org.organization_id
            }
          : null;
      })
      .filter((item): item is IFieldValue => item !== null);
    return {
      organizations: initSharingList
    };
  }, [character, organizations]);

  const [availableEveryone, setAvailableEveryone] = useState(character.availability === 'everyone');

  const [sharingList, setSharingList] = useState<ISharingList>(initialSharingList);

  const { isMobile } = useMedia();

  const organizationOptions = useMemo(() => {
    return organizations.map(org => ({
      label: <StyledCharacterLabel>{org.organization_name}</StyledCharacterLabel>,
      value: org.organization_id,
      desc: org.organization_name
    }));
  }, [organizations]);

  const [form] = Form.useForm<CharactersForm>();

  const handleAddToSharing = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const value = form.getFieldValue('organizations');

    setSharingList(prev => ({
      ...prev,
      organizations: [...prev['organizations'], ...value]
    }));
    form.setFieldValue('organizations', []);
  };

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onClose = (id: string) => {
    const filteredValues = sharingList.organizations.filter((item: IFieldValue) => item.value !== id);
    setSharingList(prev => ({
      ...prev,
      organizations: filteredValues
    }));
  };

  const handleClearAll = (e: React.MouseEvent<HTMLElement>) => {
    setSharingList({ organizations: [] });
  };

  const filteredOrganizationOptions = useMemo(() => {
    const selectedValue = sharingList.organizations.map(value => value.value);
    return organizationOptions.filter(o => !selectedValue.includes(o.value));
  }, [sharingList, organizationOptions]);

  const onChangeAvailable: CheckboxProps['onChange'] = e => {
    if (e.target.checked) {
      setSharingList({ organizations: [] });
      form.setFieldValue('organizations', []);
    }
    setAvailableEveryone(e.target.checked);
  };

  const handleValueChange = (value: CharactersForm) => {
    const formValues = form.getFieldValue('organizations');
    setIsEmptyForm(!formValues?.length);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (sharingList.organizations.length && !availableEveryone) {
        const organizations = sharingList.organizations.map(item => item.value);
        await handleAvailableChange({
          id: character.id || '',
          data: {
            availability: 'specific',
            organizations: organizations
          }
        });
        return;
      } else if (availableEveryone) {
        await handleAvailableChange({
          id: character.id || '',
          data: {
            availability: 'everyone'
          }
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <PopUpWrapper>
      <StyledPopUpContent>
        <StyledPopupHeader>
          <Typography.Text className="primary-text" style={{ fontSize: '24px', fontWeight: '700' }}>
            Access Options
          </Typography.Text>

          <div className="close" onClick={() => setShowPopupEdit(null)}>
            <img src={CloseIcon} alt={''} />
          </div>
        </StyledPopupHeader>

        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          disabled={isLoading}
          style={{ width: '100%' }}
          onValuesChange={handleValueChange}
        >
          <StyledFormItemWrapper>
            <ItemFieldsWrapper className="mb-4">
              <Form.Item
                style={{ width: '100%', marginBottom: '0', textAlign: 'left' }}
                label={<FormLabel text="Share with Organizations" />}
                name="organizations"
                getValueFromEvent={getMultValueFromEvent}
                hidden={!organizationOptions.length}
              >
                <StyledSelect
                  className="XXXXXXXXx"
                  mode="multiple"
                  labelInValue
                  options={filteredOrganizationOptions}
                  disabled={availableEveryone}
                  size="large"
                  showSearch
                  optionFilterProp="desc"
                  placeholder="Organization name"
                  loading={organizationsLoading}
                />
              </Form.Item>
              {!!organizationOptions.length && (
                <StyledSharingButton disabled={isEmptyForm || availableEveryone} onClick={handleAddToSharing}>
                  {isMobile ? 'Add' : 'Add To Sharing List'}
                </StyledSharingButton>
              )}
            </ItemFieldsWrapper>
            {!!sharingList.organizations.length && (
              <SharingList>
                {sharingList.organizations.map(item => (
                  <CustomTag
                    style={{ height: '40px' }}
                    key={item.value}
                    onMouseDown={onPreventMouseDown}
                    onClick={() => onClose(item.value)}
                  >
                    {item.label}
                    {closeIcon}
                  </CustomTag>
                ))}
                <StyledSharingButton style={{ height: '40px', width: 'fit-content', minWidth: 'unset' }} onClick={handleClearAll}>
                  Clear all
                </StyledSharingButton>
              </SharingList>
            )}
            <CheckboxWrapper>
              <CustomCheckbox onChange={onChangeAvailable} defaultChecked={availableEveryone}>
                <Typography className="regular-16 light-description">Available for everyone</Typography>
              </CustomCheckbox>
            </CheckboxWrapper>
          </StyledFormItemWrapper>

          <ButtonSubmit type="submit" disabled={isLoading || (!sharingList.organizations.length && !availableEveryone)}>
            <Space>
              {' '}
              Save Access Options
              <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="small" />
            </Space>
          </ButtonSubmit>
        </Form>
      </StyledPopUpContent>
    </PopUpWrapper>
  );
};
