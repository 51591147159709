import React from 'react';
import { RootContainer } from './assets/styles/Styled';
import { ConfigProvider, ThemeConfig } from 'antd';
import AdminRoutes from './components/AdminRoutes/AdminRoutes';
import { GoogleOAuthProvider } from '@react-oauth/google';

const rootStyle = getComputedStyle(document.documentElement);
const colorPrimary = rootStyle.getPropertyValue('--primary-color').trim();
const colorDescription = rootStyle.getPropertyValue('--light-description').trim();

const antdTheme: ThemeConfig = {
  token: {
    colorPrimary: colorPrimary,
    colorLink: colorPrimary
  },
  components: {
    Tabs: {
      itemColor: colorDescription,
      titleFontSizeLG: 20
    }
  }
};

const App: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <ConfigProvider theme={antdTheme}>
        <RootContainer>
          <AdminRoutes />
        </RootContainer>
      </ConfigProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
