import React, { useState } from 'react';
import { Form, FormProps, message, Space, Spin, Typography } from 'antd';
import CloseIcon from '../../../assets/close.png';
import { LoadingOutlined } from '@ant-design/icons';
// import { duplicateScript } from '../../../api/apiWorker';
import {
  ButtonSubmit,
  PopUpWrapper,
  StyledInput,
  StyledPopUpContent,
  StyledPopupHeader,
  StyledSharedSectionWrapper
} from '../ScriptStyles';
import { createResource } from '../../../api/apiWorker';
import { Tabs } from '../ScriptsPage';
import { useNavigate } from 'react-router-dom';
import { ISharingList, SharedForm } from '../../../components/SharedForm/SharedForm';
import { IAccessControl } from '../../../types';
import styled from 'styled-components';

interface IPopupDuplicateProps {
  setShowPopupCreateFolder: React.Dispatch<React.SetStateAction<boolean | null>>;
  setActiveTab: React.Dispatch<React.SetStateAction<Tabs>>;
}

interface CreateFolderForm {
  title: string;
}

const PopupContent = styled(StyledPopUpContent)`
  @media (max-width: 500px) {
    height: 100vh;
    border-radius: 0;
    justify-content: flex-start;
    overflow-y: auto;
    padding: 0;
  }
`;

const PopupHeader = styled(StyledPopupHeader)`
  @media (max-width: 500px) {
    padding: 20px 20px 0 20px;
  }
`;

const SharedSectionWrapper = styled(StyledSharedSectionWrapper)`
  @media (max-width: 500px) {
    padding: 20px;
  }
`;

const StyledForm = styled(Form)<FormProps<any>>`
  @media (max-width: 500px) {
    padding: 20px 20px 0 20px;
  }
`;

const StyledTypography = styled(Typography.Text)`
  @media (max-width: 500px) {
    padding: 0 20px;
  }
`;

const StyledButtonSubmit = styled(ButtonSubmit)`
  @media (max-width: 500px) {
    margin-top: auto;
    margin-bottom: 20px;
  }
`;

export const PopupCreateFolder: React.FC<IPopupDuplicateProps> = ({ setShowPopupCreateFolder, setActiveTab }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableButtonsSubmit, setIsDisableButtonSubmit] = useState(true);
  const [sharingList, setSharingList] = useState<ISharingList>({
    organizations: [],
    users: []
  });
  const [form] = Form.useForm<CreateFolderForm>();
  const navigate = useNavigate();

  const handleSubmit = async (value: CreateFolderForm) => {
    setIsLoading(true);
    setIsDisableButtonSubmit(true);
    try {
      const accessControlUsers: IAccessControl[] = sharingList.users.map(item => ({
        entity_id: item.value,
        entity_type: 'user',
        write_access: item.restriction === 'edit'
      }));
      const accessControlOrganizations: IAccessControl[] = sharingList.organizations.map(item => ({
        entity_id: item.value,
        entity_type: 'organization',
        write_access: item.restriction === 'edit'
      }));
      const data = await createResource({
        resource_type: 'folder',
        resource_name: value.title,
        access_control: [...accessControlUsers, ...accessControlOrganizations]
      });

      if (data.data?.resource_id) {
        navigate(`?folderId=${data.data.resource_id}`);
        setActiveTab('allFiles');
      }
      setShowPopupCreateFolder(false);
    } catch (e) {
      message.error('Failed creating folder');
    } finally {
      setIsLoading(false);
      setIsDisableButtonSubmit(false);
    }
  };

  const handleValueChange = () => {
    const values = form.getFieldsValue();
    if (!values.title) {
      setIsDisableButtonSubmit(true);
    } else {
      setIsDisableButtonSubmit(false);
    }
  };

  return (
    <PopUpWrapper>
      <PopupContent>
        <PopupHeader>
          <Typography.Text className="text-title fnt-open-sans" style={{ fontSize: '24px', fontWeight: '700' }}>
            Create New Folder
          </Typography.Text>

          <div className="close" onClick={() => setShowPopupCreateFolder(null)}>
            <img src={CloseIcon} alt={''} />
          </div>
        </PopupHeader>
        <StyledForm
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          disabled={isLoading}
          initialValues={{ title: `` }}
          style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
          onValuesChange={handleValueChange}
        >
          <Form.Item style={{ width: '100%', marginBottom: '0', textAlign: 'left' }} name="title">
            <StyledInput type="text" size="large" className="fnt-mulish primary-text" placeholder="Enter name" />
          </Form.Item>
        </StyledForm>
        <StyledTypography
          className="text-title fnt-open-sans"
          style={{ fontSize: '16px', fontWeight: '700', width: '100%', textAlign: 'left' }}
        >
          Sharing Options
        </StyledTypography>
        <SharedSectionWrapper>
          <SharedForm setSharingList={setSharingList} sharingList={sharingList} />
        </SharedSectionWrapper>

        <StyledButtonSubmit type="button" onClick={() => form.submit()} disabled={isDisableButtonsSubmit || isLoading}>
          <Space>
            {' '}
            Create Folder
            <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="small" />
          </Space>
        </StyledButtonSubmit>
      </PopupContent>
    </PopUpWrapper>
  );
};
