import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { PageContent } from '../../../../assets/styles/Styled';
import { Space, Spin } from 'antd';
import { useAppContext } from '../../../../context/AppContext';
import { CategoryItem } from './components/CategoryItem';
import { ImageSelectBox } from './components/ImageSelectBox';
import { useScriptForm } from '../../ScriptCreateStore';
import { LiveObjectPreviewBox } from './components/LiveObjectPreviewBox';

const Container = styled(PageContent)`
  margin: 0 auto;
`;

const CategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 8px;
`;

const SubCategory = styled.div<{ active: boolean }>`
  padding: 8px 24px;
  border-radius: 24px;
  background-color: var(--light-gray);
  color: ${props => (props.active ? 'var(--primary-color)' : 'var(--hint-color)')};
  border: 2px solid ${props => (props.active ? 'var(--primary-color)' : 'var(--light-gray)')};
  cursor: pointer;
`;

export const LiveObjectsSection = () => {
  const { liveObjects, liveObjectsBasePath: path, liveObjectsLoading } = useAppContext();
  const {
    formValues: { live_objects },
    setFormValues
  } = useScriptForm();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  useEffect(() => {
    if (liveObjects) {
      setSelectedCategory(Object.keys(liveObjects ?? {})[0]);
    }
  }, [liveObjects]);

  const subCategories = useMemo(() => {
    if (!liveObjects || !selectedCategory) return [];
    const { sub_categories } = liveObjects[selectedCategory];
    return Object.keys(sub_categories);
  }, [selectedCategory, liveObjects]);

  useEffect(() => {
    if (subCategories.length) {
      setSelectedSubCategory(subCategories[0]);
    }
  }, [subCategories]);

  const imageList = useMemo(() => {
    if (!liveObjects || !selectedCategory || !selectedSubCategory) return [];
    return liveObjects[selectedCategory].sub_categories[selectedSubCategory];
  }, [liveObjects, selectedCategory, selectedSubCategory]);

  const handleImageSelect = (imageName: string) => {
    if (!live_objects.includes(imageName)) {
      setFormValues(prev => ({ ...prev, live_objects: [...prev.live_objects, imageName] }));
    }
  };

  if (!liveObjects) return null;

  return (
    <Spin spinning={liveObjectsLoading}>
      <Container className="CONTAIR">
        <Space direction="vertical" size={24} className="w-100">
          <LiveObjectPreviewBox path={path} />
          <CategoryWrapper>
            {Object.entries(liveObjects).map(([key, val]) => (
              <CategoryItem
                key={key}
                name={key}
                imageUrl={path + val.file_name}
                active={key === selectedCategory}
                onClick={name => setSelectedCategory(name)}
              />
            ))}
          </CategoryWrapper>
          <CategoryWrapper>
            {subCategories.map(item => (
              <SubCategory
                key={item}
                active={item === selectedSubCategory}
                className="bold-16"
                onClick={() => setSelectedSubCategory(item)}
              >
                {item}
              </SubCategory>
            ))}
          </CategoryWrapper>
          <ImageSelectBox onImageSelect={handleImageSelect} path={path} imageList={imageList} selectedItems={live_objects} />
        </Space>
      </Container>
    </Spin>
  );
};
