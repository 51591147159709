import React, { useEffect, useState } from 'react';
import { PageContainer, PageWrapper, PageContent } from '../../assets/styles/Styled';
import { changeCharactersAvailability, getCharacters } from '../../api/apiWorker';
import { message, Space, Spin } from 'antd';
import { IChangeCharactersData, ICharacters } from '../../types';
import { CharactersListItem } from './CharactersListItem';
import { PopUpEditCharacters } from './PopUpEditCharacters';
import styled from 'styled-components';

const CharactersContainer = styled.div`
  margin-top: 16px;
  flex: 1;
  overflow: hidden;
  width: 100%;
`;

const StyledPageWrapper = styled(PageWrapper)`
  overflow: auto;
  @media (max-width: 768px) {
    padding: 0 24px;
  }
`;
const StyledPageContainer = styled(PageContainer)`
  height: fit-content;
`;

const StyledPageContent = styled(PageContent)`
  padding: 24px 24px 0;
`;

const CharactersPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [characters, setCharacters] = useState<ICharacters[]>([]);
  const [showPopupEdit, setShowPopupEdit] = useState<ICharacters | null>(null);

  const handleGetCharacters = async () => {
    try {
      setIsLoading(true);
      const characters = await getCharacters();
      setCharacters(characters);
    } catch (e) {
      message.error('Failed to fetch characters');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnavailable = async ({ id }: { id: string }) => {
    try {
      setIsLoading(true);
      await changeCharactersAvailability(id, {
        availability: 'no_one'
      });
      await handleGetCharacters();
    } catch (error) {
      message.error('Failed changing character availability');
      setIsLoading(false);
      console.error('Error changing character availability:', error);
    } finally {
    }
  };

  const handleAvailableChange = async ({ id, data }: { id: string; data: IChangeCharactersData }) => {
    try {
      setIsLoading(true);
      await changeCharactersAvailability(id, {
        ...data
      });
      await handleGetCharacters();
    } catch (error) {
      message.error('Failed changing character availability');
      setIsLoading(false);
      console.error('Error changing character availability:', error);
    } finally {
      setShowPopupEdit(null);
    }
  };

  useEffect(() => {
    handleGetCharacters();
  }, []);
  return (
    <StyledPageWrapper>
      <StyledPageContainer>
        <StyledPageContent>
          {showPopupEdit && (
            <PopUpEditCharacters
              setShowPopupEdit={setShowPopupEdit}
              character={showPopupEdit}
              handleAvailableChange={handleAvailableChange}
            />
          )}
          <CharactersContainer>
            <Spin spinning={isLoading} tip="Loading..." className="w-100 ">
              <Space size={12} direction="vertical" className="w-100">
                {!!characters.length &&
                  characters.map(character => (
                    <CharactersListItem
                      character={character}
                      setShowPopupEdit={setShowPopupEdit}
                      handleUnavailable={handleUnavailable}
                    />
                  ))}
              </Space>
            </Spin>
          </CharactersContainer>
        </StyledPageContent>
      </StyledPageContainer>
    </StyledPageWrapper>
  );
};

export default CharactersPage;
