import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import LoginPage from '../../pages/LoginPage';
import AdminDashboard from '../../pages/AdminDashboard';
import ScriptsPage from '../../pages/ScriptsPage';
import NewScriptsPage from '../../pages/NewScriptsPage';
import UserRegistration from '../../pages/UserRegistration/UserRegistration';
import ProtectedLayout from './ProtectedLayout';
import NotFoundPage from '../../pages/NotFoundPage';
import LandingPage from '../../pages/LandingPage';
import OrganizationsPage from '../../pages/OrganizationsPage';
import NewOrganizationPage from '../../pages/NewOrganizationPage';
import ChangeVersion from '../../pages/ChangeVersion';
import CharactersPage from '../../pages/CharactersPage';
import LandingPageGoIteens from '../../pages/LandingPageGoIteens';

const AdminRoutes = () => {
  const router = createBrowserRouter([
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/download-app',
      element: <LandingPage />
    },
    {
      path: '/download-app/go-iteens',
      element: <LandingPageGoIteens />
    },
    {
      path: '/',
      element: <Navigate to="/admin" replace />
    },
    {
      element: <ProtectedLayout />,
      children: [
        {
          path: '/admin',
          element: <AdminDashboard />
        },
        {
          path: '/user-registration',
          element: <UserRegistration />
        },
        {
          path: '/scripts',
          element: <ScriptsPage />
        },
        {
          path: '/scripts/new',
          element: <NewScriptsPage />
        },
        {
          path: '/scripts/:id',
          element: <NewScriptsPage />
        },
        {
          path: '/organizations',
          element: <OrganizationsPage />
        },
        {
          path: '/organizations/new',
          element: <NewOrganizationPage />
        },
        {
          path: '/organizations/:id',
          element: <NewOrganizationPage />
        },
        {
          path: '/version',
          element: <ChangeVersion />
        },
        {
          path: '/characters',
          element: <CharactersPage />
        }
      ]
    },
    {
      path: '*',
      element: <NotFoundPage />
    }
  ]);

  return <RouterProvider router={router} />;
};

export default AdminRoutes;
