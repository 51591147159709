import React, { useEffect } from 'react';
import { PageContainer, PageWrapper } from '../../assets/styles/Styled';
import { PageContent } from '../LandingPage/styled';
import { Spin, Typography } from 'antd';
import { ChangeVersionForm } from './ChangeVersionForm';
import styled from 'styled-components';
import { useGetCurrentVersion } from '../../hooks/useGetCurrentVersion';

const ChangeVersionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  background: #ffffff;
  height: 100%;
  width: 100%;
  max-width: 350px;
  padding: 12px;
  margin: 0 auto;
`;

const HeaderText = styled(Typography)`
  margin: 16px 0 32px 0;
`;

const ChangeVersion = () => {
  const { loading, fetchCurrentVersion, currentVersion, updateVersion } = useGetCurrentVersion();
  useEffect(() => {
    fetchCurrentVersion();
  }, []);

  return (
    <PageWrapper>
      <PageContainer>
        <PageContent>
          <ChangeVersionWrapper>
            <HeaderText className="bold-24 text-basic">Client Version</HeaderText>
            <Spin spinning={loading}>
              <ChangeVersionForm loading={loading} currentVersion={currentVersion} updateVersion={updateVersion} />
            </Spin>
          </ChangeVersionWrapper>
        </PageContent>
      </PageContainer>
    </PageWrapper>
  );
};

export default ChangeVersion;
