import { useCallback, useEffect, useState } from 'react';
import { Account } from '../types';
import { getAccounts } from '../api/apiWorker';
import { message } from 'antd';

interface Props {
  manualFetch?: boolean | undefined;
}

export const useGetAccounts = ({ manualFetch = false }: Props = {}) => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchAccounts = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAccounts();
      setAccounts(data || []);
    } catch (e) {
      message.error('Failed to fetch accounts');
      console.error('error:', e);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!manualFetch) {
      fetchAccounts();
    }
  }, [manualFetch, fetchAccounts]);

  return { accounts, loading, fetchAccounts };
};
