import React from 'react';
import { Tabs, TabsProps } from 'antd';
import styled from 'styled-components';

const CustomTabs = styled(Tabs)`
  width: 100% !important;
  height: 100% !important;

  .ant-tabs-content-holder {
    width: 100% !important;
    padding-top: 32px;
    background-color: #fff;
    height: 100% !important;
    border: 1px solid #f0f0f0;
    border-top: none;
    border-bottom: none;

    @media (max-width: 767px) {
      & {
        overflow-y: auto;
      }
    }
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-tabpane {
    height: 100%;
  }
`;

export const StyledTabs: React.FunctionComponent<TabsProps> = ({ ...props }) => {
  return <CustomTabs tabBarStyle={{ marginBottom: 0 }} {...props} />;
};
