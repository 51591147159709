export interface SelectFormItem {
  label: string;
  value: string;
}

interface IWebConfigItem {
  macos: string;
  windows: string;
}
export interface IWebConfig {
  builds_download_urls: IWebConfigItem;
  goiteens_builds_download_urls: IWebConfigItem;
  client_version: string;
}

export enum AccountStatus {
  Active = 'active',
  Deactivated = 'deactivated'
}

export type OrganizationItem = {
  organization_id: string;
  organization_name: string;
  is_admin: boolean;
};

export interface IAccountProvider {
  id?: string;
  provider: Providers;
  email?: string;
}

export interface Account {
  user_id: string;
  email: string;
  password?: string;
  created_at: string;
  role: 'mapander' | 'admin';
  status: AccountStatus | undefined;
  organizations: OrganizationItem[];
  providers: IAccountProvider[];
  first_name: string;
  last_name: string;
}

export interface AuthResponse {
  token: string;
  user: Account;
}

export interface IActionBlock {
  id: string;
  action_type: string;
  group: string;
  position_x?: number;
  position_y?: number;
  size?: number;
  uniq_id?: string;
  live_object_id?: string;
}
export interface ScenarioBlock {
  id: string;
  type: 'replica' | 'note';
  content: string;
  actions?: IActionBlock[];
}

export interface Scenario {
  id: string;
  title: string;
  blocks: ScenarioBlock[];
}

// export interface Script {
//   script_id: string;
//   users: Account[];
//   user_id?: string;
//   slides: string[];
//   script_name: string;
//   created_at: string;
//   updated_at: string;
//   organizations?: OrganizationItem[];
//   background: string;
//   live_objects: string[];
//   scenario: Scenario[];
//   copy_allowed: boolean;
// }

export interface BaseResource {
  owner_id: string;
  resource_id: string;
  resource_name: string;
  resource_type: 'script' | 'folder';
  parent_id?: string;
  created_at: string; // ALEX
  copy_allowed: boolean; //ALEX
  access_control?: IAccessControl[];
}

export interface IAccessControl {
  entity_id: string;
  entity_type: 'user' | 'organization';
  write_access: boolean;
  name?: string;
}

export interface Resource extends BaseResource {
  organization_names?: string[];
  // organizations?: OrganizationItem[];
  // users: Account[];
  // updated_at: string;
}

export type ResourceType = 'script' | 'folders' | 'all';

export interface ResourceDetail extends BaseResource {
  background: string;
  live_objects: string[];
  organizations?: OrganizationItem[];
  scenario: Scenario[];
  slides: string[];
  users: Account[];
}

export interface ResourceCreatePayload {
  resource_id?: string;
  resource_name?: string;
  resource_type?: 'folder' | 'script';
  users?: string[];
  slides?: string[];
  organizations?: string[];
  background?: string;
  live_objects?: string[];
  scenario?: Scenario[];
  copy_allowed?: boolean;
  parent_id?: string;
  access_control?: IAccessControl[];
}

export interface ScriptCreatePayload {
  script_id?: string;
  resource_name: string;
  users: string[];
  slides: string[];
  organizations?: string[];
  live_objects?: string[];
  scenario?: Scenario[];
  copy_allowed: boolean;
}

export interface ScriptDuplicatePayload {
  new_resource_name: string;
  resource_id: string;
  copy_sharing_options: boolean;
}

export enum SortOptions {
  newest = 'newest',
  eldest = 'eldest',
  az = 'az',
  za = 'za'
}

export enum FilterOptions {
  all = 'all',
  onlyMy = 'onlyMy',
  withoutOrganization = 'withoutOrganization'
}

export interface RegistrationFormValues {
  first_name?: string;
  last_name?: string;
  email: string;
  confirmEmail?: string;
  role: 'mapander' | 'admin';
  password?: string;
  organizations?: Organization[];
}

export interface Organization {
  organization_id: string;
  organization_name: string;
  users?: string[];
  admins?: string[];
}

export interface OrganizationUsers {
  users: Account[];
  admins: Account[];
}

type LiveObjectCategories = { name: string; file_name: string };
type LiveObjectObjects = { name: string; file_name: string; category: string; sub_category: string; object_id: string };

export interface LiveObjects {
  categories: LiveObjectCategories[];
  live_objects: LiveObjectObjects[];
  base_path: string;
}

export interface OrganizedLiveObjects {
  categories: Array<LiveObjectCategories & LiveObjectObjects>;
}

export enum Sections {
  NameAndShare = 'nameAndShare',
  Presentation = 'presentation',
  LiveObjects = 'liveObjects',
  Scenario = 'scenario'
}

export type Providers = 'mapanda' | 'google' | 'apple';

export interface AppleAuthResponse {
  authorization: {
    id_token: string;
    code: string;
    state?: string;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
}

export interface IUploadData {
  fields: {
    key: string;
    policy: string;
    'x-amz-algorithm': string;
    'x-amz-credential': string;
    'x-amz-date': string;
    'x-amz-security-token': string;
    'x-amz-signature': string;
  };
  url: string;
  upload_id: string;
}

export interface ScriptFormValues {
  slides: string[];
  live_objects: string[];
  resource_name: string;
  users: SelectFormItem[];
  organizations: SelectFormItem[];
  script_id: string;
  scenario: Scenario[];
}

export type FileType = File & { uid: string };
export interface IUserEdit {
  login?: string;
  first_name?: string;
  last_name?: string;
}

export interface IActionsConfigItems {
  id: string;
  icon: string;
  group: string;
}

export interface IActionsConfigPositions {
  id: string;
  icon: 'default_position' | 'left_position' | 'right_position' | 'dialogue_position';
  group: string;
}

export interface IActionsConfig {
  character_actions: IActionsConfigItems[];
  backgrounds: IActionsConfigItems[];
  character_moving_actions: IActionsConfigPositions[];
  effects: IActionsConfigItems[];
  awards: IActionsConfigItems[];
  drawing: string[];
  whiteboard: string[];
  live_object: string[];
}
export type IActionsType =
  | 'character_actions'
  | 'backgrounds'
  | 'character_moving_actions'
  | 'awards'
  | 'effects'
  | 'drawing'
  | 'whiteboard'
  | 'live_object';
export enum ActionEnum {
  character_actions = 'Play Animations',
  backgrounds = 'Change Background',
  character_moving_actions = 'Change Character Position',
  awards = 'Give Reward',
  effects = 'Effects',
  drawing = 'Paint Mode',
  whiteboard = 'Whiteboard',
  live_object = 'Live Objects'
}

export enum ActionButtonEnum {
  character_actions = 'Pick Animation',
  backgrounds = 'Change Background',
  character_moving_actions = 'Change Position',
  awards = 'Choose a reward',
  effects = 'Effects',
  drawing = 'Choose Action',
  DrawingOn = 'Turn On Paint Mode',
  DrawingOff = 'Disable Paint Mode',
  DrawingClear = 'Delete Drawing',
  whiteboard = 'Choose Action',
  WhiteboardOn = 'Open Whiteboard',
  WhiteboardOff = 'Close Whiteboard',
  WhiteboardNextSlide = 'Next Slide',
  live_object = 'Select Action',
  delete_all_live_objects = 'Delete all LO',
  place_live_objects = 'Place LO'
}

export interface IBaseConfigs {
  actions_media_base_path: string;
  live_objects_media_base_path: string;
}

export type MenuItem = {
  key: string;
  label: string;
};

export interface IPreviewConfig {
  [key: string]: string | number;
}

export interface WhiteboardPreview {
  [blockId: string]: {
    action: string;
    slideIdx: number;
    blockId: string;
    dropdownKey: string;
  }[];
}
export interface WhiteboardPreviewItems {
  action: string;
  slideIdx: number;
  blockId: string;
  dropdownKey: string;
}
export type LoItem = {
  live_object_id: string;
  dropdownKey: string;
  uniq_id: string;
  position_x?: number;
  position_y?: number;
  size?: number;
  action_type: string;
};
export type LoItemToPreview = {
  [blockId: string]: LoItem[];
};

export interface IPaintAction {
  action_type: string;
  dropdownKey: string;
}

export type PaintPreview = {
  [blockId: string]: IPaintAction[];
};
export type NewActionsItem = {
  action_type: string;
  id: string;
  dropdownKey: string;
  position_x?: number;
  position_y?: number;
  size?: number;
  uniq_id?: string;
  live_object_id?: string;
};

export type LiveObjectWithId = {
  name: string;
  object_id: string;
};
export type LiveObjectsIcons = { [key: string]: string };

export interface ICharacters {
  id: string;
  name: string | null;
  availability: 'everyone' | 'no_one' | 'specific';
  organizations: string[];
}

export interface IChangeCharactersData {
  availability: 'everyone' | 'no_one' | 'specific';
  organizations?: string[];
}
